import btoa from 'btoa';

// NetLicensing Client
import { Constants as ClientConstants } from 'netlicensing-client';

import axiosApi from '@/axios/api';

export const render = async (context, productModuleNumber) => {
  const request = {
    url: `${process.env.VUE_APP_CORE_BASE_URL}/licensingmodel/PricingTable/render`,
    method: 'get',
    params: {
      productModuleNumber,
    },
    responseType: 'text',
    headers: {
      Accept: 'text/html',
    },
  };

  const security = context.getSecurityMode();

  if (security === ClientConstants.BASIC_AUTHENTICATION) {
    request.auth = {
      username: context.getUsername(),
      password: context.getPassword(),
    };
  } else if (security === ClientConstants.APIKEY_IDENTIFICATION) {
    request.headers.Authorization = `Basic ${btoa(`apiKey:${context.getApiKey()}`)}`;
  }

  return axiosApi(request);
};

export default {
  render,
};
