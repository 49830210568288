/* ============
 * VeeValidate
 * ============
 *
 * VeeValidate is a validation library for Vue.js.
 * It has plenty of validation rules out of the box and support for custom ones as well.
 * It is template based so it is similar and familiar with the HTML5 validation API.
 *
 * https://baianat.github.io/vee-validate
 */

import Vue from 'vue';
import { ValidationProvider, ValidationObserver, configure, extend, localize } from 'vee-validate';

import en from 'vee-validate/dist/locale/en.json';

// rules
import {
  required,
  max,
  email,
  confirmed,
  alpha_dash as alphaDash,
  numeric,
  min_value as minValue,
  max_value as maxValue,
  regex,
  excluded,
  ext,
} from 'vee-validate/dist/rules';

import url from './rules/url';
import decimal from './rules/decimal';
import date from './rules/date';
import nlicNumber from './rules/nlicNumber';
import password from './rules/password';

configure({});

// turn on rules
extend('required', required);
extend('max', max);
extend('email', email);
extend('confirmed', confirmed);
extend('alphaDash', alphaDash);
extend('numeric', numeric);
extend('minValue', minValue);
extend('maxValue', maxValue);
extend('regex', regex);
extend('excluded', excluded);
extend('ext', ext);
extend('password', password);

/**
 * I don`t know why, may be it is bug in vee validation plugin,
 * but if you want use validation messages for standard and custom rules,
 * function "localize" must be call here, between extend standard rules and custom rules.
 * Otherwise, standard or custom rules use the default message instead of yours.
 */
localize('en', en);

// turn on custom rules
extend('url', url);
extend('decimal', decimal);
extend('date', date);
extend('nlicNumber', nlicNumber);

// define validation components globally
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
