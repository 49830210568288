/* ============
 * Main File
 * ============
 *
 * Will initialize the application.
 */
import Vue from 'vue';

/* ============
 * Third party
 * ============
 *
 * Various third-party libraries to initialize the application.
 */
import { Service } from 'netlicensing-client';

// api axios instance
import apiAxios from '@/axios/api';

/* ============
 * Plugins
 * ============
 *
 * Import the plugins.
 */
import VueGtag from 'vue-gtag';
import VueYandexMetrika from 'vue-yandex-metrika';
import VueToastr from 'vue-toastr';

import store from './store';
import router from './router';
import i18n from './i18n';

// use plugins
import './policies';
import './guide';
import './errors';
import './plugins/veeValidate';
import './plugins/meta';
import './plugins/sweetAlert2';
import './plugins/dayjs';
import './api';

/* ============
 * Styling
 * ============
 *
 * Import the application styling.
 */
import './assets/scss/main.scss';

/* ============
 * Main App
 * ============
 *
 * Last but not least, we import the main application.
 */
import App from './views/App';

// config
Vue.config.productionTip = process.env.NODE_ENV !== 'production';

Vue.use(VueToastr, { defaultPosition: 'toast-bottom-right' });

/**
 * Vue Analytics
 * @see https://github.com/MatteoGabriele/vue-analytics
 */
if (process.env.VUE_APP_GOOGLE_ANALYTICS_ENABLED === 'true') {
  Vue.use(VueGtag, {
    config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ACCOUNT },
    includes: [{ id: process.env.VUE_APP_GOOGLE_ADS_ACCOUNT }],
  }, router);
}

/**
 * Vue Yandex Metrika
 * @see https://github.com/vchaptsev/vue-yandex-metrika
 */
if (process.env.VUE_APP_YANDEX_METRIKA_ENABLED === 'true') {
  Vue.use(VueYandexMetrika, {
    router,
    id: process.env.VUE_APP_YANDEX_METRIKA_ACCOUNT,
    env: process.env.NODE_ENV,
    debug: process.env.NODE_ENV !== 'production',
    options: {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
    },
  });
}

// meta settings
const meta = {
  titleTemplate: '%s | NetLicensing Management Console',
};

// enable security policy
if (process.env.VUE_APP_CONTENT_SECURITY_POLICY_ENABLED === 'true') {
  meta.meta = [
    {
      'http-equiv': 'Content-Security-Policy',
      content: process.env.VUE_APP_CONTENT_SECURITY_POLICY_HEADER,
    },
  ];
}
Service.setAxiosInstance(apiAxios);

// eslint-disable-next-line no-new
new Vue({
  /**
   * Bind the Vue instance to the HTML.
   */
  el: '#app',

  /**
   * The localization plugin.
   */
  i18n,

  /**
   * The router.
   */
  router,

  /**
   * The Vuex store.
   */
  store,

  /**
   * The Vue meta.
   */
  meta,

  /**
   * Will render the application.
   *
   * @param {Function} h Will create an element.
   */
  render: (h) => h(App),
});
