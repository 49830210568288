/* ============
 * Actions for the nlic module
 * ============
 *
 * The actions that are available on the
 * nlic module.
 */
import axios from 'axios';
import dayJs from 'dayjs';
import xmlToJs from 'xml2js';

// NetLicensing-Client
import { Token, TokenService, Service, Constants as ClientConstants } from 'netlicensing-client';

/**
 * Lodash functions
 * @see https://lodash.com/docs
 */
import _get from 'lodash/get';

import itemToObject from '@/utils/itemToObject';

/**
 * Get a store token from the API, for example, is used to change the plan for the vendor and e.t.c
 *
 * @param dispatch
 * @param commit
 * @param rootState
 * @param rootGetters
 * @returns {Promise<void>}
 */
export const findShopToken = async ({ commit, rootState, rootGetters, getters }) => {
  if (!rootGetters['auth/authenticated']) {
    throw new Error('Unable to find shop token, user (vendor) is not logged in.');
  }

  if (!rootGetters['user/loaded']) {
    throw new Error('Unable to find shop token, user account is not loaded.');
  }

  const { user: { vendor }, auth: { token } } = rootState;

  let shopToken = new Token();

  shopToken.setTokenType(ClientConstants.Token.Type.SHOP);
  shopToken.setExpirationTime(dayJs(token.expirationTime));
  shopToken.setLicenseeNumber(vendor.number);

  const unguardedAxios = axios.create();
  const guardedAxios = Service.getAxiosInstance();

  // substitute the axios instance to prevent redirect if the response returns the status code 401
  Service.setAxiosInstance(unguardedAxios);

  try {
    shopToken = await TokenService.create(getters.contextWithLicenseeRole, shopToken);

    // save shop token by mutation
    commit('setShopToken', shopToken);
  } finally {
    Service.setAxiosInstance(guardedAxios);
  }
};

const fetchChangelog = async ({ commit }) => {
  if (process.env.VUE_APP_FEED_XML !== 'true' || !process.env.VUE_APP_FEED_XML_URL) {
    return;
  }

  const { data: feedXml } = await axios.get(process.env.VUE_APP_FEED_XML_URL);

  const xmlParser = xmlToJs.Parser();

  const feedJson = await xmlParser.parseStringPromise(feedXml);

  const { rss: { channel: [{ item: items }] } } = feedJson;

  const changelog = items.map((v) => {
    const [title] = v.title;
    const [description] = v.description;
    const [category] = v.category;
    const image = _get(v.enclosure, '0.$.url');
    const date = (new Date(v.pubDate[0])).toISOString();
    const [{ _: guid }] = v.guid;
    let [link] = v.link;
    let target = '_blank';

    const urlInfo = new URL(link);

    if (urlInfo.host === window.location.host) {
      target = 'self';
      link = (urlInfo.hash) ? urlInfo.hash.replace('#', '') : '/';
    }

    const record = { title, description, category, date, guid, link: { target, href: link } };

    if (image) {
      record.image = image;
    }

    return record;
  });

  commit('setChangelog', changelog);
};

const fetchProjectVersion = async ({ commit }) => {
  if (process.env.VUE_APP_ALLOW_NOAUTH_SERVICES === 'true') {
    const { data: { items: { item: [item] } } } = await axios
      .get(`${process.env.VUE_APP_CORE_BASE_URL}/userinterface/projectversion`);
    commit('setProjectVersion', itemToObject(item).projectVersion);
  }
};

const fetchDynamicEnv = async ({ commit }) => {
  if (!process.env.VUE_APP_DYNAMIC_ENV_URL) {
    return;
  }

  const { data } = await axios
    .get(`${process.env.VUE_APP_DYNAMIC_ENV_URL}`);

  commit('setDynamicEnv', data);
};

export default {
  findShopToken,
  fetchChangelog,
  fetchProjectVersion,
  fetchDynamicEnv,
};
