import btoa from 'btoa';
import atob from 'atob';

/**
 * Lodash functions
 * @see https://lodash.com/docs
 */
import _isNull from 'lodash/isNull';
import _isObject from 'lodash/isObject';
import _get from 'lodash/get';

const getStorage = () => {
  const storage = localStorage.getItem(process.env.VUE_APP_STORAGE);
  return storage ? JSON.parse(storage) : {};
};

export default {
  get(key, def) {
    if (typeof localStorage === 'undefined') {
      return def;
    }

    let value = localStorage.getItem(key);

    if (!value) {
      return def;
    }

    const storage = getStorage();
    const { secured = false } = _get(storage, key, {});

    if (secured) {
      value = atob(value);
    }

    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  },

  set(key, value, secured = true) {
    if (key === process.env.VUE_APP_STORAGE) {
      throw Error(`${key} is reserved`);
    }

    if (typeof localStorage !== 'undefined') {
      let v = value;

      if (_isNull(value) || _isObject(value)) {
        v = JSON.stringify(v);
      }

      if (secured) {
        v = btoa(v);
      }

      const storage = getStorage();

      storage[key] = { secured };

      localStorage.setItem(process.env.VUE_APP_STORAGE, JSON.stringify(storage));
      localStorage.setItem(key, v);
    }
  },

  has(key) {
    return !!(typeof localStorage !== 'undefined' && localStorage.getItem(key));
  },

  remove(key) {
    if (typeof localStorage !== 'undefined' && localStorage.removeItem) {
      const storage = getStorage();

      delete storage[key];

      localStorage.setItem(process.env.VUE_APP_STORAGE, JSON.stringify(storage));
      localStorage.removeItem(key);
    }
  },
};
