import isURL from 'validator/lib/isURL';
import i18n from '@/i18n';

export default {
  params: ['options'],
  validate(value, { options = {} }) {
    return isURL(value, options);
  },
  message: i18n.t('validations.url'),
};
