import dayjs from '@/plugins/dayjs';
import i18n from '@/i18n';

export default {
  validate(value, { format, locale = null, strict = true }) {
    if (value === 'now') {
      return true;
    }

    const args = [value, format];

    if (locale) {
      args.push(locale);
    }

    if (strict || strict === 'true') {
      args.push(true);
    }

    return dayjs(...args).isValid();
  },
  params: ['format', 'locale', 'strict'],
  message: i18n.t('validations.date'),
};
