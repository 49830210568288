import i18n from '@/i18n';

export default {
  params: ['decimals', 'separator'],
  validate(value, { decimals = '1-', separator = '.' }) {
    let decimalsRange = decimals.toString().split('-');

    if (decimalsRange.length === 1 && decimalsRange[0] !== 1) {
      decimalsRange = ['1', decimalsRange];
    }

    const [firstDecimal, secondDecimal = ''] = decimalsRange;

    return (new RegExp(`^[-+]?([0-9]+)?(\\${separator}?[0-9]{${firstDecimal},${secondDecimal}})$`))
        .test(value);
  },
  message: i18n.t('validations.decimal'),
};
