export default async function (to, from, next) {
  const { $store } = this;

  // check if user is authenticated
  if ($store.getters['auth/authenticated']) {
    if (!$store.getters['statistic/fetched'] && !$store.getters['auth/isCustomer']) {
      await $store.dispatch('statistic/fetch');
    }
  }

  return next();
}
