import { Service } from 'netlicensing-client';

export const fetchLicensesRevenueDay = (context) => Service
  .request(context, 'get', 'reporting/licenses-revenue-day');

export const fetchRequestsDay = (context, params = {}) => Service
  .request(context, 'get', 'reporting/requests-day', params);

export const fetchRevenueLicenseType = (context) => Service
  .request(context, 'get', 'reporting/revenue-license-type');

export default {
  fetchLicensesRevenueDay,
  fetchRequestsDay,
  fetchRevenueLicenseType,
};
