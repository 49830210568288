/* ============
 * Mutations for the account module
 * ============
 *
 * The mutations that are available on the
 * account module.
 */

export default {
  setShopToken(state, shopToken) {
    state.shopToken = shopToken;

    // save token number in local storage
    localStorage.setItem('NLIC_SHOP_TOKEN', btoa(JSON.stringify(state.shopToken)));
  },

  setChangelog(state, changelog) {
    state.changelog = changelog;
  },

  setProjectVersion(state, projectVersion) {
    state.projectVersion = projectVersion;
  },

  setDynamicEnv(state, env) {
    state.dynamicEnv = env;
  },
};
