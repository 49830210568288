import { Service } from 'netlicensing-client';
import btoa from 'btoa';

import apiAxios from '@/axios/api';

const offline = async (licenseeNumber, apiKeyNumber, xml = false) => apiAxios({
  url: encodeURI(`${process.env.VUE_APP_CORE_BASE_URL}/licensee/${licenseeNumber}/validate`),
  responseType: 'text',
  method: 'POST',
  headers: {
    Accept: `application/${xml ? 'xml' : 'json'}`,
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: `Basic ${btoa(`apiKey:${apiKeyNumber}`)}`,
  },
  data: Service.toQueryString({ forOfflineUse: true }),
});

export default {
  offline,
};
