/* ============
 * NProgress
 * ============
 *
 * Slim progress bars for Ajax'y applications. Inspired by Google, YouTube, and Medium.
 *
 * https://github.com/rstacruz/nprogress
 */

import NProgress from 'nprogress';
import '@/assets/scss/plugins/nprogress.scss';

NProgress.configure({ showSpinner: false });

export default NProgress;
