import Vue from 'vue';

import btoa from 'btoa';
import axios from 'axios';

/**
 * Lodash functions
 * @see https://lodash.com/docs
 */
import _get from 'lodash/get';

// vuex & router
import store from '@/store';
import router from '@/router';

/**
 * List of functions if at least one function returns true, error will not be reported.
 * @type {Array}
 */
const dontReport = [
  (e) => e.isNlicError && [200, 400, 401, 402, 403].includes(_get(e, 'response.status')),
  // eslint-disable-next-line no-underscore-dangle
  (e) => !!e._isRouter, // skip router errors
  (e) => e.name === 'SyntaxError' || e.constructor.name === 'SyntaxError', // skip chrome extensions errors
  (e) => e.name === 'AccessForbiddenError' || e.constructor.name === 'AccessForbiddenError',
  (e) => e.name === 'AuthorizationError' || e.constructor.name === 'AuthorizationError',
  (e) => e.name === 'NavigationDuplicated' || e.constructor.name === 'NavigationDuplicated',
];

/**
 * Report or log an error.
 * @param {Error} e
 * @param {Object} context
 */
const report = (e, context = {}) => {
  if (e === null) {
    return;
  }

  const { name, message, stack, response } = e;

  const globalContext = {
    userAgent: window.navigator.userAgent,
    url: router.currentRoute.fullPath,
  };

  if (response) {
    const { config = {} } = e;

    globalContext.request = {
      url: config.url,
      method: config.method,
      data: config.data,
      params: config.params,
    };

    globalContext.response = {
      status: response.status,
    };
  }

  if (store.getters['auth/authenticated']) {
    globalContext.account = { vendor: { number: _get(store.state.user, 'vendor.number') } };
  }

  const errorWithContext = { ...context, ...globalContext, name, message, stack };

  const skipReporting = dontReport.some((f) => f(e));

  const debug = process.env.NODE_ENV !== 'production';

  // for debug mode or if an error should be skipped, we use a silent report
  if (debug || skipReporting) {
    // eslint-disable-next-line no-console
    console.error(e, errorWithContext);
    return;
  }

  // send report to nlic
  if (process.env.VUE_APP_ERROR_REPORTING === 'true') {
    axios.post(process.env.VUE_APP_ERROR_REPORTING_LAMBDA_URL, errorWithContext);
  }

  // show error page
  router.push({ name: 'Error500Page', params: { base64Error: btoa(JSON.stringify(errorWithContext)) } });

  throw e;
};

Vue.use({
  install() {
    /**
     * Assign a handler for uncaught errors during component render function and watchers.
     * @param error
     * @param vm
     */
    Vue.config.errorHandler = (error) => {
      report(error);
    };

    Vue.prototype.$reportError = report;
  },
});

window.onerror = (message, source, line, column, error) => {
  report(error);
};

export default report;
