import apiAxios from '@/axios/api';
import btoa from 'btoa';

export default (context) => apiAxios({
  url: encodeURI(`${context.getBaseUrl()}/utility/backup`),
  method: 'GET',
  headers: {
    Accept: 'application/xml',
    Authorization: `Basic ${btoa(`apiKey:${context.getApiKey()}`)}`,
  },
});
