export default async function (to, from, next) {
  const { $store } = this;

  // check if dynamic env have been already loaded
  if (!$store.getters['nlic/dynamicEnvLoaded']) {
    await $store.dispatch('nlic/fetchDynamicEnv');
  }

  return next();
}
