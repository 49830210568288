// NetLicensing Client
import axiosApi from '@/axios/api';

export const activate = async (vendorNumber, promoCode) => axiosApi({
  url: process.env.VUE_APP_PROMO_LAMBDA_URL,
  method: 'post',
  validateStatus(status) {
    return ((status >= 200 && status < 300) || status === 400);
  },
  data: { vendorNumber, promoCode },
});

export default {
  activate,
};
