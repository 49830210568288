import Vue from 'vue';

/**
 * Lodash functions
 * @see https://lodash.com/docs
 */
import _get from 'lodash/get';
import _isBoolean from 'lodash/isBoolean';
import _isFunction from 'lodash/isFunction';

// vuex
import store from '@/store';

// errors
import AccessForbiddenError from '@/errors/AccessForbiddenError';

// policies

const policies = {
  api: {},
  views: {},
};

/**
 * @alias $can
 * @param ability
 * @param args
 * @return {boolean}
 */
export const can = (ability, ...args) => {
  // check before method
  let beforePath = ability.split('.');
  const methodName = beforePath.splice(-1, 1, 'before');
  beforePath = beforePath.join('.');

  const before = _get(policies, beforePath, null);

  if (_isFunction(before)) {
    const result = before.call(this, store, methodName, ...args);

    if (_isBoolean(result)) {
      return result;
    }
  }

  // check policy ability
  const method = _get(policies, ability);
  return (_isFunction(method))
      ? !!method.call(this, store, ...args)
      : false;
};

/**
 * @alias $cannot
 * @param ability string
 * @param args
 * @return {boolean}
 */
export const cannot = (ability, ...args) => !can(ability, ...args);

Vue.use({
  install(V) {
    // eslint-disable-next-line func-names
    V.prototype.$can = function (ability, ...args) {
      return can(ability, ...args);
    };

    // eslint-disable-next-line func-names
    V.prototype.$cannot = function (ability, ...args) {
      return !this.$can(ability, ...args);
    };

    // eslint-disable-next-line func-names
    V.prototype.$authorize = function (ability, ...args) {
      if (this.$cannot(ability, ...args)) {
        this.$router.push({ name: 'Error403Page' });
        throw new AccessForbiddenError();
      }
    };
  },
});
