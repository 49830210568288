/* ============
 * Actions for the account module
 * ============
 *
 * The actions that are available on the
 * account module.
 */
/**
 * Lodash functions
 * @see https://lodash.com/docs
 */
import _get from 'lodash/get';

// utils
import itemToObject from '@/utils/itemToObject';

// api
import { fetchVendor, fetchAccount } from '@/api/auth';

/**
 * Get account from API by auth.token
 *
 * @param dispatch
 * @param commit
 * @param rootState
 * @param rootGetters
 * @returns {Promise<void>}
 */
export const find = async ({ commit, rootState, rootGetters }) => {
  if (!rootGetters['auth/authenticated']) {
    throw new Error('Unable to find account, user is not logged in');
  }

  const context = rootGetters['auth/context'];

  const { auth: { token: { vendorNumber } } } = rootState;

  const query = { filter: 'validate=true;vendorData=true' };

  const { data: vendorData } = await fetchVendor(context, vendorNumber, query);
  const { data: accountData } = await fetchAccount(rootGetters['auth/context']);

  const { Vendor: vendor, ProductModuleValidation: plan = {} } = itemToObject(_get(vendorData, 'items.item'));
  const { Account: account } = itemToObject(_get(accountData, 'items.item'));

  commit('setVendor', vendor);
  commit('setAccount', account);
  commit('setPlan', plan);
};

/**
 * Reset all user data to defaults
 * @param commit
 */
export const reset = ({ commit }) => {
  commit('reset');
};

export default {
  find,
  reset,
};
