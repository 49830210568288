/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */

// NetLicensing js library
import { Constants as ClientConstants, Context, Service, Token, TokenService } from 'netlicensing-client';

/**
 * Lodash functions
 * @see https://lodash.com/docs
 */
import _isString from 'lodash/isString';

export const login = async ({ commit }, credentials) => {
  let sessionToken = null;

  if (credentials instanceof Token) {
    sessionToken = credentials;
  } else if (_isString(credentials)) {
    const context = new Context();
    context.setBaseUrl(process.env.VUE_APP_CORE_BASE_URL);
    context.setApiKey(credentials);
    context.setSecurityMode(ClientConstants.APIKEY_IDENTIFICATION);
    sessionToken = await TokenService.get(context, credentials);
  } else {
    const { username, password } = credentials;

    const context = new Context();
    context.setBaseUrl(process.env.VUE_APP_CORE_BASE_URL);
    context.setUsername(username);
    context.setPassword(password);
    context.setSecurityMode(ClientConstants.BASIC_AUTHENTICATION);

    sessionToken = new Token();
    sessionToken.setTokenType(ClientConstants.Token.Type.APIKEY);
    sessionToken.setApiKeyRole(ClientConstants.APIKEY.ROLE_APIKEY_ADMIN);
    sessionToken.setProperty('session', true);

    sessionToken = await TokenService.create(context, sessionToken);
  }

  commit('login', sessionToken);
};

export const logout = async ({ commit, dispatch }) => {
  await dispatch('user/reset', null, { root: true });
  commit('logout');
};

export const updateConsents = async ({ commit, getters }) => {
  await Service.request(getters.context, 'post', 'userinterface/updateconsents');

  // update consents
  commit('acceptPrivacyPolicy');
  commit('acceptTermsOfService');
};

export default {
  login,
  logout,
  updateConsents,
};
