import Vue from 'vue';

// api groups
import auth from './auth';
import oAuth from './oAuth';
import promoCode from './promoCode';
import reporting from './reporting';
import validation from './validation';
import pricingTable from './pricingTable';
import stripe from './stripe';
import fetchCurrencies from './currencies';
import backup from './backup';
import restore from './restore';
import hasBackupData from './hasBackupData';
import fetchStatistic from './statistic';

const $api = {
  auth,
  oAuth,
  promoCode,
  reporting,
  validation,
  pricingTable,
  stripe,
  fetchCurrencies,
  backup,
  restore,
  hasBackupData,
  fetchStatistic,
};

Vue.use({
  install(V) {
    V.prototype.$api = $api;
  },
});

export default $api;
