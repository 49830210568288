/* ============
 * Mutations for the account module
 * ============
 *
 * The mutations that are available on the
 * account module.
 */
/**
 * Lodash functions
 * @see https://lodash.com/docs
 */
import _cloneDeep from 'lodash/cloneDeep';
import _isString from 'lodash/isString';
import _forEach from 'lodash/forEach';
import _castArray from 'lodash/castArray';

import { defaults } from './state';

export default {
  setAccount(state, account) {
    const data = _cloneDeep(account);

    if (_isString(data.policy)) {
      data.policy = JSON.parse(data.policy);
    }

    // TODO(RVA) interim fix, kernel fix required
    data.policy.acceptDate = parseInt((String(data.policy.acceptDate)).padEnd(13, 0), 10);

    state.account = data;
  },

  setVendor(state, vendor) {
    state.vendor = _cloneDeep(vendor);
  },

  setPlan(state, plan) {
    const data = _cloneDeep(plan);

    // cast plan quotas
    _forEach(data, (v, k) => {
      if (v.quota !== undefined) {
        data[k].quota = parseInt(v.quota, 10);
      }
    });

    // cast active plans to array
    data.activePricingPlans = (data.activePricingPlans)
      ? _castArray(data.activePricingPlans.split(','))
      : [];

    const { pricingPlanId = '', pricingPlanName = '' } = data;

    const hasTrialPlan = data.activePricingPlans.includes('LT-PRICE-TRIAL');
    const currentIsTrialPlan = (pricingPlanId === 'LT-PRICE-TRIAL');

    data.pricingPlanNameFormatted = pricingPlanName;

    if (hasTrialPlan && !currentIsTrialPlan) {
      data.pricingPlanNameFormatted += ' + Trial Premium';
    }

    state.plan = data;
  },

  reset(state) {
    _forEach(defaults(), (v, k) => {
      state[k] = v;
    });
  },
};
