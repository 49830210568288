/* ============
 * Getters for the auth module
 * ============
 *
 * The getters that are available on the
 * auth module.
 */

// NetLicensing-Client
import { Context, Constants as ClientConstants } from 'netlicensing-client';

/**
 * Lodash functions
 * @see https://lodash.com/docs
 */
import _get from 'lodash/get';

export default {
  authenticated(state) {
    return !!_get(state.token, 'active');
  },

  context(state, getters) {
    const context = new Context();
    context.setBaseUrl(process.env.VUE_APP_CORE_BASE_URL);

    if (getters.authenticated) {
      context.setSecurityMode(ClientConstants.APIKEY_IDENTIFICATION);
      context.setApiKey(state.token.number);
    } else {
      context.setSecurityMode(ClientConstants.ANONYMOUS_IDENTIFICATION);
    }

    return context;
  },

  isVendor(state) {
    return _get(state, 'token.apiKeyRole') === 'ROLE_APIKEY_ADMIN';
  },

  isCustomer(state) {
    return _get(state, 'token.apiKeyRole') === 'ROLE_APIKEY_LICENSEE';
  },
};
