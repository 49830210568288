/* ============
 * Getters for the user module
 * ============
 *
 * The getters that are available on the
 * user module.
 */

/**
 * Lodash functions
 * @see https://lodash.com/docs
 */
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import _get from 'lodash/get';

export default {
  loaded(state) {
    return (!_isEmpty(state.vendor) && !_isEmpty(state.account));
  },

  licensingModels(state) {
    return _filter(state.plan, (v, k) => (/^LM_.*/gm).test(k));
  },

  isDemo(state) {
    const { vendor: { number } } = state;
    return (number === 'VDEMO');
  },

  /**
   * Is products limits reached?
   * @param state {{ plan: { PRODUCTS: { quota: number }} }}
   * @param getters
   * @param rootState
   * @returns {boolean}
   */
  isProductsLimitsReached(state, getters, rootState) {
    const used = _get(rootState, 'statistic.products.active', 0);
    const quota = _get(state.plan, 'PRODUCTS.quota', 0);

    if (quota === -1) {
      return false;
    }

    return used >= quota;
  },

  isLicenseesLimitsReached(state, getters, rootState) {
    const used = _get(rootState, 'statistic.licensees.active', 0);
    const quota = _get(state.plan, 'LICENSEES.quota', 0);

    if (quota === -1) {
      return false;
    }

    return used >= quota;
  },

  isLicensesLimitsReached(state, getters, rootState) {
    const used = _get(rootState, 'statistic.licenses.active', 0);
    const quota = _get(state.plan, 'LICENSES.quota', 0);

    if (quota === -1) {
      return false;
    }

    return used >= quota;
  },

  isNotificationsLimitsReached(state, getters, rootState) {
    const used = _get(rootState, 'statistic.notifications.active', 0);
    const quota = _get(state.plan, 'NOTIFICATIONS.quota', 0);

    if (quota === -1) {
      return false;
    }

    return used >= quota;
  },

  isEUVATSupportAvailable(state) {
    return _get(state.plan, 'EUVAT.valid', false);
  },

  isShopCustomizationAvailable(state) {
    return _get(state.plan, 'SHOPCUST.valid', false);
  },

  isPaypalPaymentMethodAvailable(state) {
    return _get(state.plan, 'PSP_PAYPAL.valid', false);
  },

  isStripePaymentMethodAvailable(state) {
    return _get(state.plan, 'PSP_STRIPE.valid', false);
  },

  currency(state) {
    return _get(state, 'vendor.currency', 'EUR');
  },
};
