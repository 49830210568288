export default async function (to, from, next) {
  const { $store } = this;

  // check if user is authenticated
  if ($store.getters['auth/authenticated']) {
    if (!$store.getters['user/loaded'] && !$store.getters['auth/isCustomer']) {
      await $store.dispatch('user/find');
    }
  }

  return next();
}
