// NetLicensing Client
import { itemToToken, Service } from 'netlicensing-client';
import anonymousContext from './context/anonymous';

export const login = async (provider, params) => {
  const { data: { items: { item: items } } } = await Service
    .request(anonymousContext, 'post', `userinterface/loginoauth/${provider}`, params);

  const [item] = items.filter(({ type }) => type === 'Token');

  return itemToToken(item);
};

export const register = async (provider, params) => {
  const { data: { items: { item: items } } } = await Service
    .request(anonymousContext, 'post', `userinterface/registeroauth/${provider}`, params);

  const [item] = items.filter(({ type }) => type === 'Token');

  return itemToToken(item);
};

export default {
  login,
  register,
};
