/* ============
 * State of the account module
 * ============
 *
 * The initial state of the account module.
 */

export const defaults = () => ({
  vendor: {},

  account: {
    policy: {},
  },

  plan: {
    activePricingPlans: [],
  },
});

export default defaults();
