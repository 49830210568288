/* ============
 * Actions for the account module
 * ============
 *
 * The actions that are available on the
 * statics module.
 */
/**
 * Lodash functions
 * @see https://lodash.com/docs
 */
import _get from 'lodash/get';

// utils
import itemToObject from '@/utils/itemToObject';

// api
import fetchStatistic from '@/api/statistic';

/**
 * Fetch user statistic
 *
 * @param commit
 * @param rootGetters
 * @returns {Promise<void>}
 */
export const fetch = async ({ commit, rootGetters }) => {
  if (!rootGetters['auth/authenticated']) {
    throw new Error('Unable to fetch statistic, user is not logged in');
  }

  const context = rootGetters['auth/context'];

  const { data } = await fetchStatistic(context);

  const { Statistics: statistic } = itemToObject(_get(data, 'items.item'));

  const {
    Product: products,
    ProductModule: modules,
    LicenseTemplate: templates,
    License: licenses,
    Licensee: licensees,
    Notification: notifications,
  } = statistic;

  commit('set', {
    products,
    modules,
    templates,
    licenses,
    licensees,
    notifications,
  });
};

/**
 * Reset all user data to defaults
 *
 * @param commit
 */
export const reset = ({ commit }) => {
  commit('reset');
};

export default {
  fetch,
  reset,
};
