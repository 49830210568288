import { cannot } from '@/policies';

export default async function (to, from, next, ability, ...args) {
  // check if ability can be authorized
  if (cannot(ability, ...args)) {
    // If the ability cannot be authorized, redirect to error page 403
    return next({ name: 'Error403Page' });
  }

  return next();
}
