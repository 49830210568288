import { Context, TokenService, Constants as ClientConstants } from 'netlicensing-client';
/**
 * Lodash functions
 * @see https://lodash.com/docs
 */
import _cloneDeep from 'lodash/cloneDeep';

export default async function (to, from, next) {
  const query = _cloneDeep(to.query);
  const { nlic_token: nlicToken } = to.query;

  if (nlicToken) {
    const { $store, $router } = this;

    // check if user is authenticated
    if ($store.getters['auth/authenticated']) {
      await $store.dispatch('auth/logout');
    }

    const context = new Context({
      baseUrl: process.env.VUE_APP_CORE_BASE_URL,
      securityMode: ClientConstants.ANONYMOUS_IDENTIFICATION,
    });

    const oneTimeToken = await TokenService.get(context, nlicToken);

    if (oneTimeToken) {
      if (oneTimeToken.getProperty('apiKey')) {
        // try login with apiKey
        await $store.dispatch('auth/login', oneTimeToken.getProperty('apiKey'));
      }
    }

    // delete "_token" from query string
    // eslint-disable-next-line no-underscore-dangle
    delete query.nlic_token;
    const { path, params } = to;

    $router.replace({ path, params, query });
  }

  return next();
}
