/**
 * Lodash functions
 * @see https://lodash.com/docs
 */
import _uniqueId from 'lodash/uniqueId';

// vuex & vue-router
import store from '@/store';
import router from '@/router';

// axios instance
import baseAxios from './base';

const apiAxios = baseAxios.create();

apiAxios.interceptors.request.use(async (request) => {
  // send request id
  if (process.env.VUE_APP_ERROR_REPORTING_REQUEST_INCLUDES_REQUEST_ID === 'true') {
    let requestId = '';

    if (store.getters['auth/authenticated']) {
      requestId += `${store.state.user.vendor.number}_`;
    }

    requestId += `${(new Date()).getTime()}_${_uniqueId()}`;

    if (['put', 'post', 'patch'].indexOf(request.method) >= 0) {
      const { data = {} } = request;
      request.data = { requestId, ...data };
    } else {
      const { params = {} } = request;
      request.params = { requestId, ...params };
    }
  }

  return request;
});

apiAxios.interceptors.response.use(
  (response) => response,
  async (e) => {
    const { response: { status } = {} } = e;

    if (status === 401) {
      await store.dispatch('auth/logout');
      await store.dispatch('user/reset');

      if (router.currentRoute.name !== 'LoginPage') {
        window.location.reload();
      }
    }

    throw e;
  },
);

export default apiAxios;
