/**
 * Lodash functions
 * @see https://lodash.com/docs
 */
import _each from 'lodash/each';
import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';

export default (item) => {
  const itemToObject = (source) => {
    const handle = {};

    if (!_isEmpty(source.property)) {
      _each(source.property, (p) => {
        // if value is string(boolean) type cast value to boolean
        handle[p.name] = (p.value === 'true' || p.value === 'false')
            ? (p.value === 'true')
            : p.value;
      });
    }

    if (!_isEmpty(source.list)) {
      _each(source.list, (l) => {
        handle[l.name] = itemToObject(l);
      });
    }

    if (_isArray(source)) {
      _each(source, (v) => {
        handle[v.type] = itemToObject(v);
      });

      return handle;
    }
    return handle;
  };

  return itemToObject(item);
};
