/* ============
 * Mutations for the auth module
 * ============
 *
 * The mutations that are available on the
 * account module.
 */
// NetLicensing Client
import { CastsUtils } from 'netlicensing-client';

/**
 * Lodash functions
 * @see https://lodash.com/docs
 */
import _cloneDeep from 'lodash/cloneDeep';

export default {
  login(state, token) {
    state.token = _cloneDeep(token);

    // cast string values to boolean
    state.token.privacyPolicyUpToDate = CastsUtils('boolean', state.token.privacyPolicyUpToDate);
    state.token.termsOfServiceUpToDate = CastsUtils('boolean', state.token.termsOfServiceUpToDate);
  },

  logout(state) {
    state.token = null;
  },

  acceptPrivacyPolicy(state) {
    state.token.privacyPolicyUpToDate = true;
  },

  acceptTermsOfService(state) {
    state.token.termsOfServiceUpToDate = true;
  },
};
