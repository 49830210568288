// NetLicensing Client
import { itemToToken, Service } from 'netlicensing-client';
import anonymousContext from './context/anonymous';

export const register = async (tokenNumber, params = {}) => {
  const { data: { items: { item: items } } } = await Service
    .request(anonymousContext, 'post', `token/${tokenNumber}/exec/register`, params);

  const [item] = items.filter(({ type }) => type === 'Token');

  return itemToToken(item);
};

export const confirmEmail = async (email, params = {}) => {
  const { data: { items: { item: [item] } } } = await Service
    .request(anonymousContext, 'post', 'userinterface/confirmemail', { ...params, email });

  return itemToToken(item);
};

export const resendConfirmEmail = async (confirmEmailTokenNumber) => Service
  .request(anonymousContext, 'post', `token/${confirmEmailTokenNumber}/exec/resend`);

export const sendResetPasswordEmail = async (vendorAttribute) => Service
  .request(anonymousContext, 'post', 'userinterface/resetpassword', { vendorAttribute });

export const resetPassword = async (tokenNumber, password) => Service
  .request(anonymousContext, 'post', `token/${tokenNumber}/exec/resetPassword`, { password });

export const logout = async (context) => Service.request(context, 'post', 'userinterface/logout');

export const fetchVendor = async (context, vendorNumber, params = {}) => Service
  .request(context, 'get', `vendor/${vendorNumber}`, params);

export const updateVendor = async (context, vendorNumber, vendor) => Service
  .request(context, 'post', `vendor/${vendorNumber}`, vendor);

export const fetchAccount = async (context) => Service.request(context, 'get', 'userinterface/userprofile');

export const updateAccount = async (context, account) => Service
  .request(context, 'post', 'userinterface/userprofile', account);

export const changePassword = async (context, passwordCurrent, password) => Service
  .request(context, 'post', 'userinterface/changepassword', { passwordCurrent, password });

export const sendChangeEmail = async (context, email) => Service
  .request(context, 'post', 'userinterface/changeemail', { email });

export const changeEmail = async (context, tokenNumber) => Service
  .request(context, 'post', `token/${tokenNumber}/exec/changeEmail`);

export default {
  register,
  confirmEmail,
  resendConfirmEmail,
  sendResetPasswordEmail,
  resetPassword,
  logout,
  fetchVendor,
  updateVendor,
  fetchAccount,
  updateAccount,
  changePassword,
  sendChangeEmail,
  changeEmail,
};
