/* ============
 * State of the auth module
 * ============
 *
 * The initial state of the auth module.
 */

import LongTermStorage from '@/utils/LongTermStorage';

export default {
  token: LongTermStorage.get(process.env.VUE_APP_STORAGE_AUTH_TOKEN, null),
};
