<template>
  <div id="app">
    <!-- router view -->
    <router-view/>
  </div>
</template>
<script>
/* ============
 * Entry Point
 * ============
 *
 * The entry point of the application
 */

export default {
  /**
   * The name of the application.
   */
  name: 'NetLicensingUI',

  /**
   * The methods the page can use.
   * @see https://vuejs.org/v2/api/#methods
   */
  methods: {
    resize() {
      return (window.innerWidth < 769)
        ? document.body.classList.add('body-small')
        : document.body.classList.remove('body-small');
    },
  },

  /**
   * Fires when the app has been created.
   * @see https://vuejs.org/v2/api/#created
   */
  created() {
    if (process.env.VUE_APP_ZOHO_SALESIQ_ENABLED === 'true') {
      // add Zoho SalesIQ
      window.$zoho = window.$zoho || {};
      window.$zoho.salesiq = window.$zoho.salesiq || {
        widgetcode: process.env.VUE_APP_ZOHO_SALESIQ_CODE,
        values: {},
        ready() {},
      };

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'zsiqscript';
      script.defer = true;
      script.src = 'https://salesiq.zoho.eu/widget';
      const t = document.getElementsByTagName('script')[0];
      t.parentNode.insertBefore(script, t);
    }
  },

  /**
   * Fires when the app has been mounted.
   * @see https://vuejs.org/v2/api/#mounted
   */
  mounted() {
    // add body-small class if window is wide
    this.resize();

    this.$nextTick(() => {
      // listen resize event & add body-small class if window is wide
      window.addEventListener('resize', () => this.resize());
    });
  },
};
</script>
