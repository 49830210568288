/* ============
 * State of the statistic module
 * ============
 *
 * The initial state of the statistic module.
 */

export const defaults = () => ({
  products: {
    active: 0,
    inactive: 0,
  },

  licensees: {
    active: 0,
    inactive: 0,
  },

  modules: {
    active: 0,
    inactive: 0,
  },

  templates: {
    active: 0,
    inactive: 0,
  },

  licenses: {
    active: 0,
    inactive: 0,
  },

  notifications: {
    active: 0,
    inactive: 0,
  },
});

export default defaults();
