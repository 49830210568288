import Vue from 'vue';

/**
 * GuideChimp
 * Library for tours
 * @see https://github.com/Labs64/GuideChimp
 */
// import GuideChimp from 'guidechimp';
import GuideChimp from 'guidechimp/dist/guidechimp';
import 'guidechimp/dist/guidechimp.min.css';

// plugins
import licensing from 'guidechimp/dist/plugins/licensing';
import triggers from 'guidechimp/dist/plugins/triggers';
import vueRouter from 'guidechimp/dist/plugins/vueRouter';
import lazyLoading from 'guidechimp/dist/plugins/lazyLoading';

// router
import router from '@/router';

GuideChimp.extend(licensing, { id: process.env.VUE_APP_GUIDECHIMP_USER_ID });
GuideChimp.extend(triggers);
GuideChimp.extend(vueRouter, router);
GuideChimp.extend(lazyLoading);

GuideChimp.extend((cls) => {
  const parent = {
    start: cls.prototype.start,
  };

  cls.prototype.start = function (...args) {
    return (crypto.subtle) ? parent.start.apply(this, args) : false;
  };
});

Vue.use({
  install(V) {
    V.prototype.$guide = GuideChimp;
  },
});
