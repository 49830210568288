/* ============
 * State of the nlic module
 * ============
 *
 * The initial state of the nlic module.
 */

import LongTermStorage from '@/utils/LongTermStorage';

export default {
  shopToken: LongTermStorage.get('NLIC_SHOP_TOKEN', null),
  changelog: [],
  projectVersion: '',
  dynamicEnv: null,
};
