/* ============
 * Mutations for the account module
 * ============
 *
 * The mutations that are available on the
 * account module.
 */

/**
 * Lodash functions
 * @see https://lodash.com/docs
 */
import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import _set from 'lodash/set';
import _get from 'lodash/get';
import _isString from 'lodash/isString';

import { defaults } from './state';

/**
 * @typedef {Object} Statistic
 * @property {{ active: number, inactive: number }} products
 * @property {{ active: number, inactive: number }} modules
 * @property {{ active: number, inactive: number }} templates
 * @property {{ active: number, inactive: number }} licenses
 * @property {{ active: number, inactive: number }} licensees
 * @property {{ active: number, inactive: number }} notifications
 */

export default {
  /**
   * Set statistic
   * @param state - statistic state
   * @param statistic {Statistic} - statistic object
   */
  set(state, statistic = {}) {
    _forEach(statistic, (v, k) => {
      state[k] = {
        active: parseInt(v.active, 10),
        inactive: parseInt(v.inactive, 10),
      };
    });
  },

  /**
   * Reset statistic to default state
   * @param state - statistic state
   */
  reset(state) {
    _forEach(defaults(), (v, k) => {
      state[k] = v;
    });
  },

  /**
   * Increase statistic
   * @param state
   * @param path {string|{ path: string, amount: number }}
   */
  increase(state, path) {
    const { path: p, amount = 1 } = (_isString(path)) ? { path } : path;

    if (_has(state, p)) {
      _set(state, p, _get(state, p, 0) + amount);
    }
  },

  /**
   * Decrease statistic
   * @param state
   * @param path {string|{ path: string, amount: number }}
   */
  decrease(state, path) {
    const { path: p, amount = 1 } = (_isString(path)) ? { path } : path;

    if (_has(state, p)) {
      let value = _get(state, p, 0) - amount;
      value = (value >= 0) ? value : 0;
      _set(state, p, value);
    }
  },
};
