import i18n from '@/i18n';

/**
 * Lodash functions
 * @see https://lodash.com/docs
 */
import _castArray from 'lodash/castArray';

// default password strength criteria
const defaultRules = {
  minLength: 8, // minimum password length
  minLowercase: 1, // minimum number of lowercase letters
  minUppercase: 1, // minimum number of uppercase letters
  minNumbers: 1, // minimum number of digits
  minSpecialChars: 1, // minimum number of special characters
  specialCharsRegex: /[!@#$%^&*()_+\-=[\]{};':"|,.<>/?]/, // regular expression for special characters
};

export default {
  params: ['except', 'rules'],
  validate(value, { rules: cRules, except }) {
    if (cRules !== false) {
      const rules = { ...defaultRules, ...cRules };

      const getRule = (key) => {
        const rule = rules[key];

        if (!rule) {
          return false;
        }

        return rule === true ? defaultRules[key] : rule;
      };

      const minLength = getRule('minLength');

      // check password length
      if ((minLength !== false) && (value.length < minLength)) {
        return i18n.t('validations.password.minLength', { min: minLength });
      }

      const minLowercase = getRule('minLowercase');

      // check number of lowercase letters
      if ((minLowercase !== false) && ((value.match(/[a-z]/g) || []).length < minLowercase)) {
        return i18n.t('validations.password.minLowercase', { min: minLowercase });
      }

      const minUppercase = getRule('minUppercase');

      // check number of uppercase letters
      if ((minUppercase !== false) && ((value.match(/[A-Z]/g) || []).length < minUppercase)) {
        return i18n.t('validations.password.minUppercase', { min: minUppercase });
      }

      const minNumbers = getRule('minNumbers');

      // check number of digits
      if ((minNumbers !== false) && ((value.match(/\d/g) || []).length < minNumbers)) {
        return i18n.t('validations.password.minNumbers', { min: minNumbers });
      }

      const minSpecialChars = getRule('minSpecialChars');
      const specialCharsRegex = getRule('specialCharsRegex');

      // check number of special characters
      if ((minSpecialChars !== false || specialCharsRegex !== false)
        && ((value.match(specialCharsRegex) || []).length < minSpecialChars)) {
        return i18n.t('validations.password.minSpecialChars', { min: minSpecialChars, specialCharsRegex });
      }
    }

    if (except) {
      const exceptArray = _castArray(except);

      if (exceptArray.includes(value)) {
        return i18n.t('validations.password.except', { except: exceptArray.join(',') });
      }
    }

    return true;
  },
};
