export default {
  common: {
    success: 'Success',
    error: 'Error',
    errorDescription: `Whoops! Something went wrong. Please contact us via email at
                       <a href="mailto:info@netlicensing.io">info@netlicensing.io</a>.`,
    warning: 'Warning',
    info: 'Info',
    privacyPolicy: `<a href="${process.env.VUE_APP_PRIVACY_POLICY_URL}" target="_blank">Privacy Policy</a>`,
    termsOfService: `<a href="${process.env.VUE_APP_TERMS_OF_SERVICE_URL}" target="_blank">Terms of Service</a>`,
    labs64GmbH: '<a href="https://www.labs64.com" target="_blank">Labs64 GmbH</a>',
    copy: 'Copy to Clipboard',
    copied: 'Copied to Clipboard',
    copyright: 'Copyright',
    active: 'Active',
    inactive: 'Inactive',
    modelTryAndBuy: 'Try & Buy',
    modelRental: 'Rental',
    modelSubscription: 'Subscription',
    modelFloating: 'Floating',
    modelMultiFeature: 'Multi-Feature',
    modelPayPerUse: 'Pay-per-Use',
    modelQuota: 'Quota',
    modelPricingTable: 'Pricing Table',
    modelNodeLocked: 'Node-Locked',
    modelTryAndBuyDescription: `<a href="https://netlicensing.io/wiki/try-n-buy" target="_blank">
        @:common.modelTryAndBuy</a> licensing model is ideal if you want to distribute a secure
        trial or demo version of your product. Trial licenses are granted for a short time, allowing
        customers to try the product before purchasing. Typically, production-level work is not
        permitted, and some features may be limited or disabled in demo mode. The product will be
        disabled entirely after the trial license expires unless a full-featured license is acquired.`,
    modelRentalDescription: `<a href="https://netlicensing.io/wiki/rental" target="_blank">@:common.modelRental</a>
        model is a complex licensing model, useful when you want to license multiple instances of
        an entity, each for a specified period. It can be described as independent subscriptions
        where the customer chooses the number of instances they want to subscribe to.`,
    modelSubscriptionDescription: `<a href="https://netlicensing.io/wiki/subscription" target="_blank">
        @:common.modelSubscription</a> licensing model enables the product for a specific period,
        with the option for the subscription to (auto-)renew. Different subscription periods can
        be offered, each with its own price.`,
    modelFloatingDescription: `<a href="https://netlicensing.io/wiki/floating" target="_blank">
        @:common.modelFloating</a> licensing model allows an application to be used with a specified
        number of sessions (users, hosts, etc.). The total number of active sessions is tracked,
        and the licensed application is limited by the maximum number of concurrent sessions
        purchased by the licensee.`,
    modelMultiFeatureDescription: `<a href="https://netlicensing.io/wiki/multi-feature" target="_blank">
        @:common.modelMultiFeature</a> licensing model allows enabling or disabling features of
        a product based on the user’s needs and budget. It can be used to create an upgrade path
        from a "lite" version to "standard," "pro," or "enterprise" versions without needing to
        modify or reinstall the software.`,
    modelPayPerUseDescription: `<a href="https://netlicensing.io/wiki/pay-per-use" target="_blank">
        @:common.modelPayPerUse</a> model limits the number of times a license can be used, in
        addition to its validity. License fees are based on actual usage, which can be measured
        by metrics like CPU load, network bandwidth, or other application-related parameters.`,
    modelQuotaDescription: `<a href="https://netlicensing.io/wiki/quota" target="_blank">
        @:common.modelQuota</a> licensing model defines capacity limits (e.g., units, requests, volume, etc.)
        for specific product features.`,
    modelPricingTableDescription: `Model and manage pricing plans and product features effectively
        using <a href="https://netlicensing.io/wiki/pricing-table" target="_blank">@:common.modelPricingTable</a>
        licensing model. You can manage customers and track their memberships with the ability to
        enable/disable individual features per customer.`,
    modelNodeLockedDescription: `<a href="https://netlicensing.io/wiki/node-locked" target="_blank">
        @:common.modelNodeLocked</a> licensing model ties a product to a node-specific secret,
        such as a hardware hash, hardware ID (dongle), user login data, or a generated random string.`,
    select: 'Select',
    selected: '{count} selected',
    selectAll: 'Select all',
    deselectAll: 'Deselect all',
    refresh: 'Refresh',
    gridView: 'Change to grid view',
    listView: 'Change to list view',
    detailView: 'Change to detail view',
    deleteSelected: 'Delete (deactivate) selected',
    activateSelected: 'Activate selected',
    deactivateSelected: 'Deactivate selected',
    unsavedChangesTitle: 'Discard changes?',
    unsavedChangesDescription: 'You have unsaved changes that will be lost.',
    unsavedChangesConfirmButton: 'Discard',
    unsavedChangesCancelButton: 'Cancel',
    roleApikeyAnalytics: 'Analytics',
    roleApikeyOperation: 'Operation',
    roleApikeyMaintenance: 'Maintenance',
    roleApikeyAdmin: 'Administrator',
    roleApikeyLicensee: 'Licensee',
    featureIsNotIncludedInPlan: `This feature is not included in your plan. For more info, visit the
        <a href="#/usage">"Usage"</a> page.`,
    actions: 'Action',
    create: 'Create',
    update: 'Update',
    cancel: 'Cancel',
    obtain: 'Obtain',
    remove: 'Delete',
    warningLevelGray: 'Unknown',
    warningLevelRed: 'Underlicensed',
    warningLevelYellow: 'Warning',
    warningLevelGreen: 'Licensed',
    unknownOAuth: 'Unknown OAuth provider',
    manageNodeSecrets: 'Nodes management',
    manageNodeSecretsHint: 'Allow customers to manage nodes in the customer portal',
    manageSessions: 'Sessions management',
    manageSessionsHint: 'Allow customers to manage sessions in the customer portal',
    downloadDataCsv: 'Download CSV',
    event: {
      licenseeCreated: 'Create Customer',
      licenseCreated: 'Create License',
      warningLevelChanged: 'Change Warning Level',
    },
    preview: 'Preview',
    view: 'View',
    add: 'Add',
    addTag: 'Add Tag',
    tagKey: 'Key',
    tagValue: 'Value',
    noTags: 'No tags defined',
    name: 'Name',
    number: 'Number',
    numberHint: 'Leave the "Number" field blank to get an automatically generated unique number',
    price: 'Price',
    description: 'Description',
    product: 'Product',
    bundle: 'Bundle',
    day: 'Day',
    week: 'Week',
    month: 'Month',
    year: 'Year',
    hour: 'Hour',
    minute: 'Minute',
    days: 'Days',
    hours: 'Hours',
    minutes: 'Minutes',
  },

  validations: {
    /**
     * Placeholders:
     * {_field_} represents the field name.
     * {_value_} represents the field value.
     * {_rule_} represents the rule name.
     *
     * @see https://baianat.github.io/vee-validate/guide/localization.html#messages-format
     */
    url: 'The {_field_} field is not a valid URL.',
    decimal: 'The {_field_} field must be numeric and may contain up to {decimals} decimal points.',
    date: 'The {_field_} field is not a valid date.',
    nlicNumber: `The {_field_} field may only contain alphanumeric characters, @, +, %, &, $, colon, dot, dash, or
    underscore.`,
    password: {
      minLength: 'The password must be at least {min} characters long.',
      minLowercase: 'The password must contain at least {min} lowercase letter(s).',
      minUppercase: 'The password must contain at least {min} uppercase letter(s).',
      minNumbers: 'The password must contain at least {min} digit(s).',
      minSpecialChars: 'The password must contain at least {min} special character(s) (examples: !, @, #, $).',
      except: 'The password cannot be: {except}.',
    },
  },

  tours: {
    welcome: {
      name: 'Welcome to NetLicensing!',
      title1: `This tour will guide you through NetLicensing and show you the tools
            and features available right at your fingertips.`,
      description1: `Once logged in, get an instant overview of your customers recent activity.
            The dashboard provides key NetLicensing metrics, such as purchases and license validations.`,
      title2: 'Demo Data',
      description2: `Get started in seconds by instantly creating your first product configuration
            in NetLicensing. Simply choose a Licensing Model of interest and click "Create".<br>
            No worries: Creating demo data is free of charge and can be deleted or created again at any time.
            <br> Use the "Products" and "Customers" views to explore the newly created entities,
            which are required for the chosen licensing model to operate correctly.`,
      title3: 'Products',
      description3: `You can find the created demo product in your Products list.
            This configuration includes all the necessary elements (such as the chosen licensing model and packages),
            allowing you to start using NetLicensing immediately.`,
      title4: 'Customers',
      description4: `Let's explore the Customers list. A customer is the holder of
            licenses, typically representing the end user.`,
      button4: 'See how to validate a customer',
      title5: 'Settings',
      description5: `Begin with the "Settings" section. The parameters in this section affect your entire account,
            such as API Keys, acceptable payment methods, TTL, and integration with the NetLicensing API.`,
      title6: 'Fill in your profile data',
      description6: `Once registered, complete your profile. An up-to-date vendor profile is essential for
            accurate legal representation in the NetLicensing Shop, as well as for correctly calculating VAT
            for your customers when they purchase licenses.`,
      title7: 'Usage & Limits',
      description7: `See your NetLicensing usage statistics at a glance, including plan details, validity,
            and available features. You can also easily upgrade your plan by clicking "Change Plan".`,
      title8: 'Useful links to get you started',
      description8: `Here are a few useful links to help you kick-start your license management journey:
            <br><br>
            <ul>
              <li><a href="https://netlicensing.io/getting-started/" target="_blank">Getting Started</a></li>
              <li><a href="https://netlicensing.io/wiki/" target="_blank">Wiki</a></li>
              <li><a href="https://netlicensing.io/wiki/restful-api" target="_blank">RESTful API</a></li>
              <li><a href="https://netlicensing.io/wiki/faq" target="_blank">FAQ</a></li>
              <li><a href="https://netlicensing.io/wiki/changelog" target="_blank">Changelog</a></li>
            <ul>`,
    },
    productCreate: {
      name: 'Create Product',
      title1: 'Create Product',
      description1: 'Click on "@:ProductsList.addProduct" to create a new product.',
      title2: 'Product Details',
      description2: `Provide details for your product and click "@:ProductForm.create".<br>
            At a minimum, specify the product name and version.`,
    },
    productCreateFull: {
      name: 'Create Product',
      title1: 'Products Navigation Menu',
      description1: `You can create and manage your products in the Products view.<br>
            Use the top navigation menu to open the Products view.`,
      title2: 'Products View',
      description2: `In the Products view, you can create and manage your products,
            as well as deactivate and delete selected products.`,
      title3: '@:tours.productCreate.title1',
      description3: '@:tours.productCreate.description1',
      title4: '@:tours.productCreate.title2',
      description4: '@:tours.productCreate.description2',
      title5: 'Step finished',
      description5: 'Congratulations! You have successfully created your first product!',
    },
    moduleCreate: {
      name: 'Create Module',
      title1: 'Add Module',
      description1: 'Click on "@:ModulesList.addModule" to start a new module configuration.',
      title2: 'Module Configuration',
      description2: `Provide configuration details for your module and click "@:ModuleForm.create".<br>
            Depending on the selected licensing model, you need to specify one or
            more packages to be offered to your customers.`,
    },
    moduleCreateFull: {
      name: 'Create Module',
      title1: 'Products View',
      description1: 'Click on the product name to open the product to which the module needs to be added.',
      title2: 'Modules List View',
      description2: `The Modules view shows all configured product modules.<br>
            Here, you can add a new module to the product, or deactivate and delete selected modules.`,
      title3: '@:tours.moduleCreate.title1',
      description3: '@:tours.moduleCreate.description1',
      title4: '@:tours.moduleCreate.title2',
      description4: '@:tours.moduleCreate.description2',
      title5: 'Step finished',
      description5: 'Congratulations! You have successfully added and configured a module!',
    },
    customerCreate: {
      name: 'Create Customer',
      title1: 'Create Customer',
      description1: 'Click on "@:CustomersList.addCustomer" to create a new customer.',
      title2: 'Customer Details',
      description2: `Provide the customer profile details, such as name and product assignment,
            and click "@:CustomerForm.create".<br> Additionally, you can store customer data as key-value pairs
            or <a href="https://netlicensing.io/wiki/faq-add-customer-data" target="_blank">structured data</a>
            in the "Tags" tab.`,
    },
    customerCreateFull: {
      name: 'Create Customer',
      title1: 'Customers Navigation Menu',
      description1: `You can create and manage your customers in the Customers view.<br>
            Use the top navigation menu to open the Customers view.`,
      title2: 'Customers View',
      description2: `In the Customers view, you can create and manage customers,
            as well as deactivate and delete selected customers.`,
      title3: '@:tours.customerCreate.title1',
      description3: '@:tours.customerCreate.description1',
      title4: '@:tours.customerCreate.title2',
      description4: '@:tours.customerCreate.description2',
      title5: 'Step finished',
      description5: 'Congratulations! You have successfully created a new customer!',
    },
    licenseCreate: {
      name: 'Create License',
      title1: 'Add License',
      description1: 'Click on "@:LicensesList.addLicense" to assign a new license.',
      title2: 'License Details',
      description2: `Provide license details and click "@:LicenseForm.create".<br>
            Choose a standard Module and Package configuration,
            or assign individual limits to the customer's license.`,
    },
    licenseCreateFull: {
      name: 'Create License',
      title1: 'Customers View',
      description1: 'Click on the customer name to open the customer to whom the licenses need to be assigned.',
      title2: 'Licenses List',
      description2: `The Licenses list shows all licenses assigned to the customer.<br>
            Here, you can assign a new license, or deactivate and delete selected licenses.`,
      title3: '@:tours.licenseCreate.title1',
      description3: '@:tours.licenseCreate.description1',
      title4: '@:tours.licenseCreate.title2',
      description4: '@:tours.licenseCreate.description2',
      title5: 'Step finished',
      description5: 'Congratulations! You have successfully assigned a new license to the customer!',
    },
    sendValidation: {
      name: 'Validate Customer',
      title1: 'Customers View',
      description1: 'Choose and open the customer for whom you want to trigger a validation request.',
      title2: 'API Examples',
      description2: 'Open the "@:CustomerForm.tabApiExamples" tab.',
      title3: 'Validation Request',
      description3: `Here you can see how the customer validation request is built,
            as well as view a sample validation response for the selected customer.`,
      title4: 'cURL Command',
      description4: 'Copy this cURL command to send a validation request from the command line.',
      title5: 'API Test Center',
      description5: 'Alternatively, you can use our API Test Center to build and send a test validation request.',
      apiTestCenterButton: 'Open API Test Center',
    },
  },

  LicensesList: {
    delete: 'Delete | Delete license | Delete {count} licenses',
    deleteOnlyInactive: 'Only inactive licenses can be deleted',
    deleteTitle: 'Delete license | Delete {count} licenses',
    deleteDescription: `This will permanently delete the license.
            <p>Please type <strong>{key}</strong> to confirm.</p>`,
    deleteConfirmButton: 'Delete',
    deactivateTitle: 'Deactivate license | Deactivate {count} licenses',
    deactivateDescription: 'This may affect validation results for customers.',
    deactivateConfirmButton: 'Deactivate',
    listHeaderName: 'License Name',
    listHeaderStatus: 'Status',
    listHeaderPrice: 'Price',
    listHeaderProduct: 'Product',
    listHeaderModule: 'Module',
    listHeaderTemplate: 'Package',
    listDateCreated: 'Date Created',
    noLicenses: 'No licenses available',
    filterName: 'Filter licenses',
    filterState: 'Show all licenses',
    addLicense: 'Add license',
    addLicenseNoModules: 'Please create module configuration first',
    nodeSecrets: 'Secrets ({used}/{quantity})',
    deleteNodeSecretTitle: 'Delete secret',
    deleteNodeSecretDescription: `The device-to-customer number binding will be released and
            can be established again.`,
    deleteNodeSecretConfirmButton: 'Delete',
    sessions: 'Sessions ({used}/{quantity})',
    deleteSessionTitle: 'Release session',
    deleteSessionDescription: `The session checked out by the user will be released
            and can be used by the same or another user.`,
    deleteSessionConfirmButton: 'Release',
    obtainBundle: 'Obtain bundle',
  },

  TheLayoutDefaultHeader: {
    welcome: 'Welcome to Labs64 NetLicensing',
    dashboard: 'Dashboard',
    products: 'Products',
    customers: 'Customers',
    changePlan: 'Change Plan',
    logout: 'Logout',
    backToConsole: 'Switch to the legacy Management Console',
    sendIssue: 'Create a new community support ticket',
    showTour: 'Show notable NetLicensing Console features',
    showChangelog: 'See the latest NetLicensing news and announcements',
    bookASession: 'Book a session',
  },

  VendorOnBoardingChecklist: {
    title: 'Get Started with NetLicensing',
    createProduct: 'Create a product',
    createModule: 'Configure a module',
    createCustomer: 'Create a customer',
    createLicense: 'Add a license',
    sendValidation: 'Validate a customer',
    goToNextStep: 'Go to the next step',
    completePreviousStep: 'Please complete the previous step',
    getStarted: 'Get Started',
  },

  LicenseForm: {
    tabGeneral: 'General',
    tabTags: 'Tags',
    tabSecrets: 'Secrets',
    tabSessions: 'Sessions',
    active: 'Active',
    inactive: 'Inactive',
    name: 'Name',
    number: 'Number',
    numberHint: 'Leave "Number" field blank to get an automatically generated unique number',
    dateCreated: 'Date Created',
    status: 'Status',
    price: 'Price',
    licensee: 'Customer',
    module: 'Module',
    moduleSelect: 'Select module',
    moduleSelectCustomerFirst: 'Please select customer before select module',
    template: 'Package',
    create: 'Create',
    update: 'Update',
    day: 'Day',
    week: 'Week',
    month: 'Month',
    year: 'Year',
    days: 'Days',
    hours: 'Hours',
    minutes: 'Minutes',
    period: 'Duration',
    quantity: 'Quantity',
    usedQuantity: 'Used Quantity',
    maxSessions: 'Max Sessions',
    parentFeature: 'Parent Feature',
    parentFeatureHint: 'The number of the FEATURE license this TIMEVOLUME license is associated with',
    parentFeaturesEmpty: 'List is empty. Please create the Parent Feature license first',
    parentFeatureLicense: 'parent feature license',
    parentFeatureChildren: 'Related licenses',
    chooseParentFeature: 'Choose Parent Feature',
    tryAndBuyPeriod: 'Trial Period',
    tryAndBuyStartDateHint: `(Optional) If not specified, the start date will be set upon first validation.
      @:LicenseForm.startDateHint`,
    validityRange: 'Validity Range',
    startDate: 'From',
    startDateHint: `The date on which this license will become effective. Use format ('YYYY-MM-DD') or
    keyword 'now' to set the date to current.`,
    endDate: 'To',
    quotaQuantity: 'Quota',
    advanced: 'Advanced',
    hidden: 'Hidden',
    hiddenHint: 'If checked, the license will not be shown to the end user as already purchased a license.',
    addTag: 'Add Tag',
    tagKey: 'Key',
    tagValue: 'Value',
    noTags: 'No tags defined',
    addSecret: 'Add Secret',
    secret: 'Secret',
    secretGenerate: 'Generate Secret',
    noSecrets: 'No secrets',
    sessionId: 'Session ID',
    sessionDate: 'Expiration Timestamp',
    sessionExpires: 'Expires',
    noSessions: 'No Sessions',
    sku: 'SKU',
  },

  BundlesList: {
    addBundle: 'Add new bundle',
    filterName: 'Filter bundles',
    filterProduct: 'Any product',
    filterState: 'Any state',
    deleteOnlyInactive: 'Only inactive bundles can be deleted',
    templates: '1 Package| {count} Packages',
    listHeaderName: 'Name',
    listHeaderDescription: 'Description',
    listHeaderPrice: 'Price',
    listHeaderStatus: 'Status',
    noBundles: 'No Bundles',
    deleteTitle: 'Delete bundle | Delete {count} bundles',
    deleteDescription: `This will permanently delete all bundles.
            <p>Please type <strong>{key}</strong> to confirm.</p>`,
    deleteConfirmButton: 'Delete',
    deactivateTitle: 'Deactivate bundle | Deactivate {count} bundles',
    deactivateDescription: '',
    deactivateConfirmButton: 'Deactivate',
    products: '{count} Product| {count} Products',
    packages: '{count} Package| {count} Packages',
    stalePackages: '{count} Stale Package | {count} Stale Packages',
  },

  BundleForm: {
    selectProduct: 'Please select a product',
    tabGeneral: 'General',
    tabTags: 'Tags',
    packages: 'Packages',
    priceHint: 'Leave "Price" field blank to get the price calculated as the sum of the package prices',
    product: 'Product',
    productHint: `A bundle can be limited to containing packages from only one product, which means that changing the
    product in the bundle would require removing all the packages`,
    noModules: 'No modules',
    stalePackage: 'Stale package with the number "{number}"',
    viewProduct: 'View product',
  },

  TheSettings: {
    tabGeneral: 'General',
    tabApiKeys: 'API Access',
    tabPaymentMethods: 'Payment Methods',
    tabShopCustomization: 'Shop Customization',
    tabNotifications: 'Notifications',
    apiKeyFilterNumber: 'Filter API Keys',
    apiKeyFilterRole: 'Show all',
    selectApiKeyRole: 'Select a role for the new API Key',
    addApiKey: 'Add API Key',
    listHeaderNumber: 'API Key',
    listHeaderName: 'Name',
    listHeaderRole: 'Role',
    listHeaderCustomer: 'Customer',
    listHeaderDate: 'Date Created',
    listHeaderProtocol: 'Type',
    listHeaderEvent: 'Events',
    listHeaderStatus: 'Status',
    listHeaderActions: 'Action',
    deleteApiKeys: 'Delete | Delete API Key| Delete {count} API Keys',
    deleteApiKeysTitle: 'Delete API Key',
    deleteApiKeysDescription: `This will permanently delete the API Key.
            <p>Please type <strong>{key}</strong> to confirm.</p>`,
    deleteApiKeysConfirmButton: 'Delete',
    deleteApiKeysSuccessMessage: 'API Key deleted | {count} API Keys deleted',
    noApiKeys: 'No API Keys',
    newsletter: 'Newsletter',
    newsletterDescription: 'I want to receive important news from Labs64',
    validation: 'Validation',
    ttlInterval: 'TTL Interval',
    days: 'day | days',
    hours: 'hour | hours',
    minutes: 'minute | minutes',
    save: 'Update',
    paymentMethods: 'Payment Methods',
    paymentMethodName: 'Payment Method',
    paymentMethodDescription: 'Description',
    paypal: 'PayPal',
    paypalDescription: `PayPal is the safer, easier way to make an online payment either with your existing
            PayPal account or a Credit/Debit Card. Visa, Mastercard and American Express are accepted.`,
    paypalSubject: 'Subject',
    paypalSubjectHint: 'Typically this is the email address connected with your PayPal account',
    paypalSandbox: 'PayPal (Sandbox)',
    paypalSandboxDescription: `Sandbox is a testing environment where you can make calls to the PayPal
            operations without affecting any real PayPal users or their live PayPal accounts.`,
    stripe: 'Stripe',
    stripeDescription: `Stripe currently supports businesses in the US, Canada, UK, Australia,
            and many European countries. You can instantly accept payments from around the world.`,
    stripeTesting: 'Stripe (Testing)',
    stripeTestingDescription: `Stripe test mode allows you to fake having a Stripe balance in order to test
            (fake) transfers.`,
    active: 'Active',
    inactive: 'Inactive',
    paymentMethodDoesNotInPlan: 'Your plan does not include this payment method.',
    connectStripe: 'Connect to Stripe',
    connectStripeConfTitle: 'Connect to Stripe',
    connectStripeConfText: `Stripe can't be connected in test and live mode at the same time,
            therefore the other connection will be dropped when connected here.`,
    connectStripeConfConfirmButton: 'Connect',
    disconnectStripe: 'Disconnect Stripe',
    changePlan: 'Change Plan',
    shopColor: 'Color',
    shopPrimaryColor: 'Primary Color',
    shopLogo: 'Logo',
    shopLinks: 'Links',
    shopSuccessUrl: 'Success URL',
    shopSuccessUrlHint: `Take customers to this URL when they finish checkout.
            URL Format: https://netlicensing.io`,
    shopSuccessUrlTitle: 'Success URL Title',
    shopCancelUrl: 'Cancel URL',
    shopCancelUrlHint: `Take customers to this URL when they cancel their checkout.
            URL Format: https://netlicensing.io`,
    shopCancelUrlTitle: 'Cancel URL Title',
    shopCheckout: 'Checkout',
    shopCollectCustomerInfo: 'Collect Customer Info',
    shopLogoHint: 'Only JPG, PNG and GIF formats allowed. Recommended size is 200x55.',
    currency: 'Currency',
    customerNotFound: 'Customer "{number}" not found',
    addNotification: 'Add Notification',
    notificationFilterNumber: 'Filter Notification',
    notificationFilterState: 'Show all',
    notificationFilterEvent: 'Event',
    noNotifications: 'No Notifications',
    deactivateNotificationTitle: 'Deactivate notification | Deactivate {count} notifications',
    deactivateNotificationDescription: 'This might affect notifications delivery.',
    deactivateNotificationConfirmButton: 'Deactivate',
    deleteNotificationTitle: 'Delete notification | Delete {count} notifications',
    deleteNotificationDescription: `Please note that after deleting notification(s),
            you won't be able to undo this action.
            <p>Please type <strong>{key}</strong> to confirm.</p>`,
    deleteNotificationConfirmButton: 'Delete',
  },

  NotificationForm: {
    tabGeneral: 'Notification',
    name: 'Name',
    number: 'Number',
    numberHint: 'Leave "Number" field blank to get an automatically generated unique number',
    events: 'Events',
    eventsHint: '',
    protocol: 'Type',
    typeHint: `At the moment only the WEBHOOK notification type is supported.
            <a href="https://netlicensing.io/wiki/changelog" target="_blank">Stay tuned</a>
            to NetLicensing news for any upcoming changes or additions.`,
    payload: 'Payload',
    payloadHint: `You can use JSONPath syntax within the payload, such as {$.timestamp}.
    To see available placeholders, press "Ctrl+Space" or start typing "{$" to trigger suggestions.`,
    // eslint-disable-next-line no-template-curly-in-string
    payloadPlaceholder: 'Event: {$}',
    endpoint: 'Endpoint',
    endpointHint: 'HTTP POST request with the payload specified below will be sent to this endpoint',
    placeholders: {
      root: 'General data covering all categories',
      timestamp: 'Timestamp representing the date and time of the event',
      origin: 'Source of the data',
      entities: 'List of entities related to this event',
      event: 'The event that occurred',
      eventName: 'The name of the event identifying its type',
      licensee: {
        root: 'All licensees',
        first: 'The first licensee',
        last: 'The last licensee',
        active: 'Active licensees',
        inactive: 'Inactive licensees',
        numbers: 'The number of all licensees',
        products: 'The product numbers associated with all licensees',
      },
      license: {
        root: 'All licenses',
        first: 'The first license',
        last: 'The last license',
        active: 'Active licenses',
        inactive: 'Inactive licenses',
        numbers: 'The number of all licenses',
        status: 'The statuses (true/false) of all licenses',
        licensees: 'The licensee numbers associated with all licenses',
        licenseTemplates: 'The template numbers associated with all licenses',
      },
      validation: {
        root: 'All validation results',
        first: 'The first validation result',
        last: 'The last validation result',
        valid: 'Valid validation result',
        invalid: 'Invalid validation result',
        moduleNumbers: 'The product module numbers associated with all validation results',
        warningLevels: 'The warning level statuses of all validation results',
        licensingModels: 'The licensing models associated with all validation results',
      },
    },
  },

  UsageLimits: {
    unlimited: 'Unlimited',
    limits: 'Limits',
    products: 'Active products ',
    productsUsages: '{used} of {available}',
    modules: 'Product Modules (per product)',
    modulesUsages: '{used} of {available}',
    customers: 'Customers',
    customersUsages: '{used} of {available}',
    licenses: 'Licenses',
    licensesUsages: '{used} of {available}',
    notifications: 'Notifications',
    notificationsUsages: '{used} of {available}',
    features: 'Features',
    licensingModels: 'Licensing Models',
    paymentMethods: 'Payment Methods',
    paypal: 'PayPal',
    paypalTesting: 'PayPal (Sandbox)',
    stripe: 'Stripe',
    stripeTesting: 'Stripe (Testing)',
  },

  ModuleForm: {
    name: 'Name',
    number: 'Number',
    numberHint: 'Leave the "Number" field blank to automatically generate a unique number',
    model: 'Model',
    create: 'Create',
    update: 'Update',
    packages: 'Packages',
    packageDescription: 'Package Description',
    addPackage: 'Add Package',
    oneTime: 'One-Time',
    timePeriod: 'Time Period',
    gracePeriod: 'Grace Period',
    gracePeriodHint: `If enabled, the template defines a grace period of validity granted after subscription expiration.
    Should be hidden and not automatic.`,
    automatic: 'Default',
    automaticHint: `If enabled, every new customer will automatically receive one license from this component
    (regardless of its price). May be predefined for some license types and Licensing Models.`,
    errors: {
      quantity: '{field} must specify a positive integer or -1 (infinity)',
      warningLevelRedBeforeYellow: '@:common.warningLevelRed must be after @:common.warningLevelYellow',
    },
    warningLevelGreen: '<span class="text-navy">@:common.warningLevelGreen</span>',
    warningLevelYellow: '<span class="text-warning">@:common.warningLevelYellow</span>',
    warningLevelRed: '<span class="text-danger">@:common.warningLevelRed</span>',
    tryAndBuy: {
      timeVolume: 'Trial Period',
      warningLevel: `In the "@:common.modelTryAndBuy" license model, the status "@:ModuleForm.warningLevelGreen" is
      assigned when the permanent license is valid. The status "@:ModuleForm.warningLevelRed" is assigned if the
      license is invalid. The "@:ModuleForm.warningLevelYellow" status is assigned when a temporary (trial)
      license is in use.`,
    },
    rental: {
      price: 'Base Price',
      priceHint: 'Feature price',
      warningLevel: `When the number of days remaining until expiration exceeds the value specified in the
      "@:ModuleForm.warningLevelYellow" field, the warning level will be "@:ModuleForm.warningLevelGreen". If it falls
      between the values of "@:ModuleForm.warningLevelYellow" and "@:ModuleForm.warningLevelRed",
      the warning level will be "@:ModuleForm.warningLevelYellow". If the remaining days are less than or
      equal to "@:ModuleForm.warningLevelRed", the warning level will be "@:ModuleForm.warningLevelRed".`,
    },
    subscription: {
      warningLevel: `In the "@:common.modelSubscription", the status "@:ModuleForm.warningLevelGreen"
      is assigned when licenses are valid. The status "@:ModuleForm.warningLevelRed" is assigned
      when there are no valid licenses or the subscription has expired.
      "@:ModuleForm.warningLevelYellow" is assigned when the remaining time is below the threshold
      specified in "@:common.warningLevelYellow".`,
    },
    floating: {
      maxSessions: 'Max Sessions',
      maxCheckoutValidity: 'Max Checkout Validity',
      warningLevel: `In the "@:common.modelFloating" license model, the status "@:ModuleForm.warningLevelGreen"
      is assigned when the licenses are valid. The status "@:ModuleForm.warningLevelRed" is assigned when there are no
      licenses available or all sessions have been used. The status "@:ModuleForm.warningLevelYellow" is assigned when
      the available number of sessions has dropped below the threshold specified in the
      "@:common.warningLevelYellow" field.`,
    },
    quota: {
      quantity: 'Quota',
      warningLevel: `In the @:common.modelQuota model, only two warning levels are used:
      "@:ModuleForm.warningLevelGreen" and "@:ModuleForm.warningLevelRed". "@:ModuleForm.warningLevelGreen"
      indicates a valid license, while "@:ModuleForm.warningLevelRed" means the license is invalid
      or the quota has been exceeded.`,
    },
    payPerUse: {
      quantity: 'Quantity',
      warningLevel: `In the @:common.modelPayPerUse license model, when the license is valid and enough uses remain,
      the status is "@:ModuleForm.warningLevelGreen". If the number of available uses falls below the threshold
      in "@:common.warningLevelYellow", the status is "@:ModuleForm.warningLevelYellow".
      If no uses remain or the available quantity is exhausted, the status is "@:ModuleForm.warningLevelRed".`,
    },
    pricingTable: {
      warningLevel: `In the "@:common.modelPricingTable" license model, there are two pricing plans:
      "One Time" and "Subscription". For the "One Time" plan, "@:ModuleForm.warningLevelGreen"
      indicates valid licenses, and "@:ModuleForm.warningLevelRed" means invalid licenses.
      For the "Subscription" plan, the warning levels function the same, with "@:ModuleForm.warningLevelYellow"
      indicating a threshold of remaining time.`,
      enterName: 'Enter Name',
      enterDescription: 'Enter Description',
      enterValue: 'Enter value or -1 for unlimited',
      planName: 'Plan Name',
      skuName: 'SKU Name',
      description: 'Enter Description',
      automaticHint: 'If enabled, the Pricing Plan will be assigned automatically',
      hiddenHint: 'If enabled, the Pricing Plan will not be visible to the customer',
      deleteHint: 'Delete',
      priceHint: 'Enter Price',
      feature: 'Feature',
      quantity: 'Quantity',
      negate: 'Negate',
      negateHint: 'Enable this option if the SKU should be used in negate mode',
      addPlan: 'Add Plan',
      addSku: 'Add SKU',
      skuNumber: 'Number',
      skuNumberHint: `Unique SKU number used for validation,
            leave blank for automatic generation`,
      planNumber: 'Number',
      planNumberHint: 'Unique plan number, leave blank for automatic generation',
      advanced: 'Show number',
      actionGenerateHint: 'Generate Action URL',
      actionGeneratedConfTitle: 'Generate a new Action URL?',
      actionGeneratedConfText: 'Existing Action URL will be overwritten!',
      actionGeneratedConfConfirmButton: 'Generate',
      actionUrl: 'Action URL',
      actionUrlHint: 'Specify the URL for plan acquisition (e.g., eCommerce, Sales contact form)',
      actionTitle: 'Action Title',
      actionTitleHint: 'Specify the Action Title shown in the Pricing Table',
      copyHtmlConfTitle: 'Unsaved Data',
      copyHtmlConfText: 'Please save your changes before copying the Pricing Table HTML code.',
      copyHtmlConfConfirmButton: 'Update & Copy',
      copy: 'Copy Code',
      copied: 'Pricing Table HTML code snippet copied to clipboard.',
    },
    nodeLocked: {
      warningLevel: `In the @:common.modelNodeLocked license model, "@:ModuleForm.warningLevelGreen"
      means valid licenses. "@:ModuleForm.warningLevelYellow" indicates the available node secrets
      have dropped below the threshold. "@:ModuleForm.warningLevelRed" means there are no valid
      licenses or available node secrets.`,
      mode: 'Mode',
      modeClient: 'Client',
      modeClientHint: 'In this mode, the secret is set by the client during the first validation call',
      modePredefined: 'Predefined',
      modePredefinedHint: `The secret must be explicitly set in NetLicensing
      (cannot be used if Licensee Auto-create mode is enabled for the product)`,
      quantity: 'Nodes',
      quantityHint: 'Specifies the number of node secrets that can be assigned to customers',
    },
    multiFeature: {
      warningLevel: `In the @:common.modelMultiFeature model, two warning levels are used:
      "@:ModuleForm.warningLevelGreen" for valid licenses and "@:ModuleForm.warningLevelRed" for invalid licenses.`,
    },
    validationError: 'Please fill out all required fields',
    configurationWasFixed: 'Pricing Table configuration fixed automatically:',
    applyToUpdateChanges: 'Please click Update to apply these changes.',
    mistakePlanWeight: 'Plan priority defined for "{name} [{number}]"',
    planInUseHint: 'Billing frequency cannot be changed for plans currently in use.',
    modelTryAndBuy: '@:common.modelTryAndBuy - Allow product trial before purchase',
    modelRental: '@:common.modelRental - Lease or rent product instances',
    modelSubscription: '@:common.modelSubscription - Define time-limited packages',
    modelFloating: '@:common.modelFloating - Control concurrent product usage',
    modelMultiFeature: '@:common.modelMultiFeature - Enable single product features',
    modelPayPerUse: '@:common.modelPayPerUse - Metered product/service usage',
    modelQuota: '@:common.modelQuota  - Add limits/quota to product features',
    modelPricingTable: '@:common.modelPricingTable - Manage product pricing plans and features',
    modelNodeLocked: '@:common.modelNodeLocked - Restrict product usage to specific devices',
    hidden: 'Hide in Shop',
    hiddenHint: 'If enabled, licenses from this package will not be visible for purchase in the Shop.',
    warningLevel: 'Warning Levels',
  },

  VendorForm: {
    tab1: 'General',
    tab2: 'Legal Information',
    tab3: 'Address',
    tab4: 'Affiliate',
    name: 'Name',
    email: 'Email',
    website: 'Website',
    phone: 'Phone',
    number: 'Number',
    type: 'Type',
    typePrivate: 'Private',
    typeCompany: 'Company',
    typeHint: `Choose "Company" if you are a business and are required to explicitly calculate
              and show VAT to your customers in the NetLicensing Shop.
              If you're not subject to VAT, choose "Private".`,
    vat: 'Tax/VAT ID',
    vatHint: `The VAT ID is only relevant for vendors with the type 'Company' within the EU.
              Please note that the VAT ID consists of two letters identifying the country
              and a country-specific number of digits.
              Enter your VAT ID in accordance with your country-specific format.
              Private vendors (individuals) should leave this field blank.`,
    term: 'Terms of Service',
    termHint: 'A URL to the Terms of Service. Example: https://example.com/terms',
    privacy: 'Privacy Policy',
    privacyHint: 'A URL to the Privacy Policy. Example: https://example.com/privacy',
    imprint: 'Imprint',
    imprintHint: 'A URL to the Imprint. Example: https://example.com/imprint',
    address1: 'Address 1',
    address2: 'Address 2',
    city: 'City',
    zip: 'Zip/Postal Code',
    country: 'Country',
    save: 'Update',
    affiliate: 'Affiliate Link',
    affiliateHint: `With your affiliate link, you earn commissions for any paying referrals generated
              through this link.`,
    affiliateAcceptTerms: `I agree to the NetLicensing Affiliate Program
              <a href="https://www.labs64.com/legal/affiliate-program-agreement/" target="_blank">Terms</a>.`,
    emailHint: 'Want to change your email?',
    clickHere: 'Click here',
    changeEmail: {
      title: 'Update Your Email',
      description: `Please enter your new email address, and we will send you an email with a link to
        update your email.`,
      input: 'Enter your new email address',
      confirm: 'Submit',
      success: {
        title: 'Email Sent',
        description: `An email has been sent to the provided address. Please check your inbox and
          click the link to update your email. If you don’t see the email, check your spam or junk folder.`,
      },
    },
  },

  ProductsList: {
    addProduct: 'Add New Product',
    addProductUsingDemoData: 'Add New Product Using Demo Data',
    delete: 'Delete | Delete Product | Delete {count} Products',
    deleteOnlyInactive: 'Only inactive products can be deleted',
    deleteTitle: 'Delete Product | Delete {count} Products',
    deleteDescription: `This will permanently delete all related modules, customers, and licenses.
            <p>Please type <strong>{key}</strong> to confirm.</p>`,
    deleteConfirmButton: 'Delete',
    deactivateTitle: 'Deactivate Product | Deactivate {count} Products',
    deactivateDescription: 'This may affect validation results for customers.',
    deactivateConfirmButton: 'Deactivate',
    listHeaderName: 'Name',
    listHeaderDescription: 'Description',
    listHeaderVersion: 'Version',
    listHeaderStatus: 'Status',
    listHeaderActions: 'Action',
    noProducts: 'No Products',
    filterName: 'Filter Products',
    filterState: 'Show All',
    modules: '{count} Module | {count} Modules',
    customers: '{count} Customer | {count} Customers',
  },

  CustomerForm: {
    tabGeneral: 'General',
    tabTags: 'Tags',
    tabAliases: 'Aliases',
    tabApiExamples: 'API Examples',
    tabOfflineValidation: 'Offline Validation',
    tabWarningLevel: 'Warning Level',
    create: 'Create',
    update: 'Update',
    product: 'Product',
    module: 'Module',
    markedForTransfer: 'Marked for Transfer',
    advanced: 'Advanced',
    licenseeSecret: 'Licensee Secret',
    licenseeSecretHint: '<span class="text-nlic">Deprecated</span>, use the Node-Locked licensing model instead.',
    addAlias: 'Add Alias',
    alias: 'Alias',
    noAliases: 'No aliases defined',
    validationEx: 'Validation',
    validationExDescription: `NetLicensing's Customer-centric license management approach allows you to retrieve
            all customer’s licenses using single validate call.<br>
            Please refer to the <i>LicenseeService.validate()</i> RESTful API documentation
            <a href="https://netlicensing.io/wiki/licensee-services#validate-licensee" target="_blank">here</a>.`,
    shopEx: 'Shop Link',
    shopExDescription: `NetLicensing Shop is an optional component, which provides you with an all-in-one
            functionality to allow licenses acquisition and issuing accordingly to your product configuration.<br>
            Use below RESTful API sample to generate an individual customer’s portal token (validity: 30min).
            <i>“customerPortalURL”</i> attribute in the response can be used to forward the customer to the
            Customer Portal.<br>Please refer to the <i>TokenService.createToken()</i> RESTful API documentation
            <a href="https://netlicensing.io/wiki/token-services#create-token" target="_blank">here</a>.`,
    customerPortalEx: 'Customer Portal',
    customerPortalExDescription: `NetLicensing Customer Portal is an online hosted page that works as a helpful tool
            for your customers to access their licensing information, such as licenses, transaction history,
            account information, vendor contact data, and many more options relevant to them.<br>
            Use below RESTful API sample to generate an individual customer’s shop token (validity: 30min).
            <i>“shopURL”</i> attribute in the response can be used to forward the customer to the NetLicensing Shop.<br>
            Please refer to the <i>TokenService.createToken()</i> RESTful API documentation
            <a href="https://netlicensing.io/wiki/token-services#create-token" target="_blank">here</a>.`,
    customerPortalExHint: `We advise you to copy this link and open it in a different browser;
            otherwise, you will be logged out from your current vendor session.`,
    customerPortalExLink: 'Customer Portal Link',
    request: 'Request',
    response: 'Response',
    responseHint: `To avoid the validation from this page to interfere occasionally with the real validation
            from your product, 'dryRun' is fixed to 'true' on this page. For some licensing models this may result
            in 'valid=false' in the response you see here, this is because 'dryRun' does not simulate full behavior,
            instead it just prevents any changes to licenses as a result of the call, including changes required for a
            successful validation. Please consult the corresponding <a href="https://l64.cc/nlLM" target="_blank">
            licensing model documentation</a> for details.`,
    url: 'URL',
    method: 'Method',
    status: 'Status',
    headers: 'Headers',
    parameters: 'Parameters',
    cURL: 'cURL',
    dryRunHint: `This example contain "dryRun" attribute, which needs to be omitted in your
            production integration.`,
    dryRunWarn: `Below example contain "dryRun" attribute, which needs to be omitted in your production
            integration.`,
    addModule: '@:ModulesList.addModule',
    noModules: 'Please create module configuration first',
    wrongModelsForOfflineValidation: `Note: Offline validation file doesn’t support metered licensing models
            such as Floating, Node-Locked and Pay-per-Use.`,
    apiKey: 'API Key',
    selectApiKey: 'Select API Key',
    download: 'Download',
    validationIsNotSigned: `Warning: An API Key without
            <a href="https://netlicensing.io/wiki/signing-the-response" target="_blank">signing response</a> support
            used and thus doesn’t offer sufficient protection against tampering.`,
    warningLevel: 'Warning Level',
    noWarningLevel: 'No Data',
    reRequest: 'Re-request',
    expirationTimeHint: 'The expiration time must be in datetime format and set to a future date and time.',
  },

  TheLayoutDefaultRightSidebar: {
    changelog: 'What\'s new',
    learnMore: 'Learn more',
    seeChangelog: 'See the full changelog...',
  },

  TheLayoutDefaultFooter: {
    help: 'Help',
    netlicensing: 'NetLicensing.IO',
  },

  CheckboxToggle: {
    on: 'On',
    off: 'Off',
  },

  InputPassword: {
    strengthMessagesVeryWeak: 'Very Weak',
    strengthMessagesWeak: 'Weak',
    strengthMessagesMedium: 'Medium',
    strengthMessagesStrong: 'Strong',
    strengthMessagesVeryStrong: 'Very Strong',
  },

  SelectMulti: {
    placeholder: 'Select an option',
    tagPlaceholder: 'Press Enter to create a tag',
    selectLabel: 'Press Enter to select',
    selectGroupLabel: 'Press Enter to select a group',
    selectedLabel: 'Selected',
    deselectLabel: 'Press Enter to remove',
    deselectGroupLabel: 'Press Enter to deselect a group',
    limitText: '`and {count} more',
  },

  ProductForm: {
    tabGeneral: 'General',
    tabLogo: 'Logo',
    tabVat: 'VAT',
    tabDiscounts: 'Discounts',
    tabTags: 'Tags',
    active: 'Active',
    inactive: 'Inactive',
    version: 'Version',
    description: 'Description',
    licensingInfo: 'Licensing Info',
    vatMode: 'VAT Calculation Mode',
    vatModeGross: 'Gross',
    vatModeNet: 'Net',
    vatModeHint: `Choose whether prices are shown as gross or net. If you sell to business
                    customers, you usually quote prices without VAT, as they can usually reclaim it.
                    Retail prices for private customers are generally expected to include VAT.`,
    addDiscount: 'Add Discount',
    totalPrice: 'Total Price',
    amount: 'Amount',
    noDiscounts: 'No discounts defined',
    addCustomProperty: 'Add Tag',
    customPropertyKey: 'Key',
    customPropertyValue: 'Value',
    noCustomProperties: 'No tags defined',
    create: 'Create',
    update: 'Update',
    advanced: 'Advanced',
    licenseeAutoCreate: 'Auto-create customer',
    licenseeAutoCreateHint: `If checked, unknown customers will be created automatically on first validation
                               instead of causing a validation error.`,
    logoHint: 'Only JPG, PNG, and GIF formats are allowed. Recommended size is 400x250.',
    downloadLink: 'Download Link',
    downloadLinkHint: 'Enter URL',
  },

  CustomersList: {
    delete: 'Delete | Delete customer | Delete {count} customers',
    deleteOnlyInactive: 'Only inactive customers can be deleted',
    deleteTitle: 'Delete customer | Delete {count} customers',
    deleteDescription: `This will permanently delete the customer and all associated licenses.
                          <p>Please type <strong>{key}</strong> to confirm.</p>`,
    deleteConfirmButton: 'Delete',
    deactivateTitle: 'Deactivate customer | Deactivate {count} customers',
    deactivateDescription: 'This might affect validation results for the customers.',
    deactivateConfirmButton: 'Deactivate',
    addCustomer: 'Add new customer',
    addCustomerNoProduct: 'Please create product configuration first',
    listHeaderName: 'Name',
    listHeaderProduct: 'Product',
    listHeaderStatus: 'Status',
    listHeaderActions: 'Action',
    noCustomers: 'No customers',
    filterName: 'Filter customers',
    filterProduct: 'Any product',
    filterState: 'Any state',
    filterWarningLevel: 'Any warning level',
    refreshWarningLevel: 'Refresh warning level',
  },

  ModulesList: {
    delete: 'Delete | Delete module | Delete {count} modules',
    deleteOnlyInactive: 'Only inactive modules can be deleted',
    deleteTitle: 'Delete module | Delete {count} modules',
    deleteDescription: `This will permanently delete the module.
                          <p>Please type <strong>{key}</strong> to confirm.</p>`,
    deleteConfirmButton: 'Delete',
    deactivateTitle: 'Deactivate module | Deactivate {count} modules',
    deactivateDescription: 'This might affect validation results for the customers.',
    deactivateConfirmButton: 'Deactivate',
    listHeaderName: 'Module Name',
    listHeaderModel: 'Model',
    listHeaderStatus: 'Status',
    noModules: 'No modules',
    filterName: 'Filter modules',
    filterState: 'Show all',
    addModule: 'Add module',
  },

  TransactionsList: {
    listHeaderNumber: 'Transaction Number',
    listHeaderSource: 'Source',
    listHeaderDateCreated: 'Date Created',
    listHeaderDateClosed: 'Date Closed',
    listHeaderStatus: 'Status',
    statusClosed: 'Closed',
    statusCancelled: 'Cancelled',
    statusPending: 'Pending',
    sourceShop: 'Shop Transaction',
    sourceAutoLicenseCreate: 'Auto Transaction for License Creation',
    sourceAutoLicenseUpdate: 'Auto Transaction for License Update',
    sourceAutoLicenseDelete: 'Auto Transaction for License Deletion',
    sourceAutoLicenseeCreate: 'Auto Transaction for Customer Creation',
    sourceAutoLicenseeDelete: 'Auto Transaction for Customer Deletion',
    sourceAutoLicenseeValidate: 'Auto Transaction for Customer Validation',
    sourceAutoLicensetemplateDelete: 'Auto Transaction for Package Deletion',
    sourceAutoProductDelete: 'Auto Transaction for Product Deletion',
    sourceAutoLicensesTransfer: 'Auto Transaction for License Transfer',
    sourceObtainBundle: 'Obtain Bundle Transaction',
    noTransactions: 'No transactions',
  },

  TransactionForm: {
    number: 'Number',
    status: 'Status',
    statusClosed: 'Closed',
    statusCancelled: 'Cancelled',
    statusPending: 'Pending',
    source: 'Source',
    sourceShop: 'Shop Transaction',
    sourceAutoLicenseCreate: 'Auto Transaction for License Creation',
    sourceAutoLicenseUpdate: 'Auto Transaction for License Update',
    sourceAutoLicenseDelete: 'Auto Transaction for License Deletion',
    sourceAutoLicenseeCreate: 'Auto Transaction for Customer Creation',
    sourceAutoLicenseeDelete: 'Auto Transaction for Customer Deletion',
    sourceAutoLicenseeValidate: 'Auto Transaction for Customer Validation',
    sourceAutoLicensetemplateDelete: 'Auto Transaction for Package Deletion',
    sourceAutoProductmoduleDelete: 'Auto Transaction for Module Deletion',
    sourceAutoProductDelete: 'Auto Transaction for Product Deletion',
    sourceAutoLicensesTransfer: 'Auto Transaction for License Transfer',
    sourceObtainBundle: 'Bundle Acquisition Transaction',
    dateCreated: 'Date Created',
    dateClosed: 'Date Closed',
    country: 'Country',
    company: 'Company',
    taxId: 'Tax/VAT ID',
    vat: 'VAT',
    vatMode: 'VAT Mode',
    discount: 'Discount',
    grandTotal: 'Grand Total',
    currency: 'Currency',
    paymentMethod: 'Payment Method',
    paymentMethodNull: '"Null" Payment Method',
    paymentMethodDirectDebit: 'Direct Debit',
    paymentMethodPaypal: 'PayPal',
    paymentMethodPaypalSandbox: 'PayPal (Sandbox)',
    paymentMethodStripe: 'Stripe',
    paymentMethodStripeTesting: 'Stripe (Testing)',
    paymentMethodGoogleCheckout: 'Google Checkout',
    consentTimestamp: 'Consent Timestamp',
    consentPrivacyPolicyURL: 'Consent Privacy Policy',
    consentTermsOfServiceURL: 'Consent Terms Of Service',
  },

  VendorChangePassword: {
    username: 'Username',
    oldPassword: 'Old Password',
    password: 'Password',
    passwordConfirmation: 'Password Confirmation',
    changePassword: 'Change Password',
  },

  TokenForm: {
    tabGeneral: 'API Key',
    tabTags: 'Tags',
    apiKeyRole: 'Show All',
    apiKeyRoleDescription: `Choose an
                <a href="https://netlicensing.io/wiki/security#api-key-identification" target="_blank">API Key Role</a>
                that grants access to a specific API subset.`,
    role: 'API Key Role',
    privateKey: 'Private Key',
    privateKeyDescription: `(optional) Provide your private key (RSA-2048) to
                <a href="https://netlicensing.io/wiki/signing-the-response" target="_blank">sign</a>
                the validation response.`,
    create: 'Create',
    name: 'Name',
    nameDescription: `(optional) Provide an API Key name to set the context, description, or other
                relevant information.`,
    customer: 'Customer',
    customerSelect: 'Select Customer',
    customerSearch: 'Start typing to search for Customer',
    customerDescription: '(optional) Restrict API Key scope to the selected customer.',
  },

  ChartRequestsDay: {
    apiRequests: 'API Requests per Day',
    validationRequests: 'Validations per Day',
    countOfApiRequests: 'API Requests',
    countOfValidationRequests: 'Validation Requests',
    pastWeek: 'Past Week',
    pastMonth: 'Past Month',
    allTime: 'All Time',
    updated: 'Updated on {date}',
  },

  ChartRevenueLicenseType: {
    updated: 'Updated on {date}',
    empty: '<No Data>',
  },

  ChartLicensesRevenueDay: {
    label: 'Licenses/Revenue per Day',
    pastWeek: 'Past Week',
    pastMonth: 'Past Month',
    allTime: 'All Time',
    yAxesLabel: 'Amount (EUR)',
    updated: 'Updated on {date}',
  },

  ChartLicenseeWarningLevel: {
    updated: 'Updated on {date}',
    empty: '<No Data>',
  },

  PopupDateTimePicker: {
    confirm: 'Ok',
    cancel: 'Cancel',
  },

  TheUpdateConsents: {
    description: `We updated our &nbsp; @:common.privacyPolicy &nbsp; and &nbsp; @:common.termsOfService .
            Click Accept to let us know you're okay with the updates.`,
    accept: 'Accept',
  },

  Dropzone: {
    fileUpload: 'Drag and drop or click here to upload your image',
    fileIsTooBig: 'File is too large ({size}). Max file size: {maxSize}.',
    fileWrongType: 'You can\'t upload files of this type.',
    fileUrlIsNotImage: 'Provided value is not a valid image URL',
    fileUrlIsNotCorrect: 'Provided value is not a valid image URL',
    delete: 'Remove image',
    enterUrl: 'Please provide a valid image URL',
  },

  DemoData: {
    licensingModel: 'Licensing Model',
    fixedPart: 'Fixed Part',
    fixedPartHint: `Entities will be created using the following number format:
            <TypePrefix>-<FixedPart>-<UniqueId>-<Counter>-DEMO`,
  },

  OAuthButton: {
    signIn: 'Sign In',
  },

  LoginPage: {
    title: 'Login',
    welcome: 'Welcome to Labs64 NetLicensing',
    login: 'Sign In',
    loginGitHub: 'Sign In with GitHub',
    loginAuth0: 'Sign In with Auth0',
    loginAzure: 'Sign In with Microsoft',
    register: 'Create an Account',
    username: 'Email',
    password: 'Password',
    forgotPassword: 'Forgot Password?',
    haveAccount: 'Don’t have an account?',
    401: `Bad Credentials
            <a href="https://netlicensing.io/wiki/faq-console-cannot-login" target="_blank">Help</a>`,
    woops: 'Operation could not be completed. Please try again. If the problem persists, contact support.',
    oAuth401: 'No account found or the email is not accessible via the OAuth authentication provider.',
    oAuthCreateAccount: 'Account Not Found',
    oAuthCreateAccountDescription: 'No NetLicensing vendor account was found. Would you like to create one?',
    oAuthCreateAccountConfirm: 'Create My Vendor Account',
    oAuthRegister: `A NetLicensing vendor account associated with your {provider} profile was not found.
        Please create an account.`,
  },

  ProfilePage: {
    title: 'Profile',
    saveSuccess: 'Profile saved',
    changePassword: 'Change Password',
    changePasswordSuccess: 'Password changed successfully',
    pricingPlan: 'Your current plan is {pricingPlan}',
    changePlan: 'Change Plan',
    toSPP: `You have accepted
              <a href="${process.env.VUE_APP_TERMS_OF_SERVICE_URL}" target="_blank">
              Terms of Service (v{versionTermsOfService})
              </a>
              and
              <a href="${process.env.VUE_APP_PRIVACY_POLICY_URL}" target="_blank">
              Privacy Policy (v{versionPrivacyPolicy})
              </a>
              on {date}`,
    githubStudentDeveloperPackHeader: 'GitHub Student Developer Pack',
    githubStudentDeveloperPackBody: `As an active member of the "GitHub Student Developer Pack" program,
              you are eligible to apply for the NetLicensing Basic Plan for FREE while you are a student or teacher.
              <a target="_blank" href="https://netlicensing.io/github-students/">More info</a>`,
    githubStudentDeveloperPackButton: 'Claim NetLicensing for Students or Teachers offer',
    githubStudentDeveloperPackSuccess: `Successfully Validated Status!<br/>We were able to
              validate your status. Your free Basic Plan will be assigned to your vendor account within
              a few hours.`,
    githubStudentDeveloperPackFail: `Can't Validate Status!<br/>We were unable to validate
              your status. Please make sure that you have an active membership in the
              GitHub Student Developer Pack program.`,
    githubStudentDeveloperPackStudentProcess: 'NetLicensing Basic Student Plan status: pending',
    githubStudentDeveloperPackStudentAccepted: 'NetLicensing Basic Student Plan status: accepted',
    githubStudentDeveloperPackTeacherProcess: 'NetLicensing Basic Teacher Plan status: pending',
    githubStudentDeveloperPackTeacherAccepted: 'NetLicensing Basic Teacher Plan status: accepted',
    moreInfo: 'More Info',
    oAuthChangePassword: `You are logged in with an OAuth provider. To change your password,
              use the <a href="{href}" target="_blank">forgot password</a> function.`,
  },

  RegistrationPage: {
    title: 'Register',
    username: 'Username',
    email: 'Email',
    password: 'Password',
    passwordConfirmation: 'Password Confirmation',
    fullName: 'Full Name or Company Name',
    acceptTOSandPP: 'I agree to the Terms of Service and Privacy Policy',
    acceptNewsletter: 'I want to receive important news from Labs64',
    register: 'Register',
    confirm: 'Confirm',
    resend: 'Resend',
    haveAccount: 'Already have an account?',
    login: 'Login',
    promoCode: 'Promo Code',
    backToLogin: 'Back to Login',
    confirmationTitle: 'Thank you! Your registration was successful.',
    confirmationDescription: `You will shortly receive an email from us. Please confirm your registration
                               using the link in the email to activate your NetLicensing account.`,
    activationTitle: 'Thank you! Your account has been activated.',
    activationDescription: `Your NetLicensing vendor account has been successfully activated, and you are ready
                to configure your first product. Please proceed to the login page using the link below to begin working
                with Labs64 NetLicensing.`,
    activationError: 'Account activation failed',
    emailSent: `We've sent an email to {email}.<br>
                  Click the confirmation link in that email to begin using Labs64 NetLicensing.`,
    emailNotArrived: `Still can't find the email in your inbox or junk/spam folder?<br>
                        Try sending it again.`,
    startAgain: 'Start Over',
    tokenInvalid: 'Confirmation link expired, please start registration again',
    promoCodeActivated: 'Promo Code {code} activated',
    signUpGitHub: 'Sign Up with GitHub',
    signUpAuth0: 'Sign Up with Auth0',
    signUpAzure: 'Sign Up with Microsoft',
  },

  ForgotPasswordPage: {
    title: 'Forgot Password',
    instructions: `Reset your password in three easy steps to keep it secure:
                    <ul>
                        <li>Fill in your email address or vendor number</li>
                        <li>We'll email you a temporary link</li>
                        <li>Use the link to change your password on our secure website</li>
                    </ul>
                    You may need to check your spam folder or whitelist info@netlicensing.io.`,
    vendorAttribute: 'Email Address or Vendor Number',
    sendPassword: 'Submit',
    login: 'Login',
    emailSent: 'We\'ll send you a password reset link to your email address.',
    confirmation: `We'll send you a password reset link to your email address after validating your account.
                    If you don't receive the email within a few minutes, please check your spam folder and junk
                    filters, and ensure that the entered email or vendor number is correct.
                    If you still have trouble resetting your password, please contact us.`,
    error: `Whoops, something went wrong. Please contact us by email at
              <a href="mailto:info@netlicensing.io">info@netlicensing.io</a>.`,
    password: 'New Password',
    passwordConfirmation: 'Confirm New Password',
    notRequested: 'Didn\'t request a password reset or changed your mind?',
    passwordReset: 'Password Reset',
    resetPassword: 'Reset Password',
    startAgain: 'Start Over',
    resend: 'Resend',
  },

  BundlesPage: {
    title: 'Bundles',
    deleteSuccessMessage: 'Bundle deleted | {count} bundles deleted',
  },

  BundleCreatePage: {
    title: 'Create bundle',
    bundleCreated: 'Bundle created',
  },

  BundleEditPage: {
    title: '{number} | {name} [{number}]',
    bundleUpdated: 'Bundle updated',
  },

  SettingsPage: {
    title: 'Settings',
    saved: 'Settings saved',
    removeApiKeysSuccessMessage: 'API Key deleted | {count} API Keys deleted',
    removeNotificationsSuccessMessage: 'Notification deleted | {count} Notifications deleted',
  },

  NotificationCreatePage: {
    title: 'Create Notification',
    notification: 'Notification',
    notificationCreated: 'Notification created',
  },

  NotificationEditPage: {
    title: '{number} | {name} [{number}]',
    notification: 'Notification',
    notificationUpdated: 'Notification updated',
  },

  BackupRestorePage: {
    title: 'Backup & Restore',
    tabs: {
      backup: 'Backup',
      restore: 'Restore',
    },
    products: 'Products',
    modules: 'Modules',
    templates: 'Templates',
    licensees: 'Customers',
    licenses: 'Licenses',
    transactions: 'Transactions',
    tokens: 'Tokens (non-expiring)',
    paymentMethods: 'Payment Methods',
    shopCustomization: 'Shop Customization',
    notifications: 'Notification Settings',
    backup: 'Backup',
    backupFollowingItems: 'Will back up the following items:',
    restore: 'Restore',
    restoreErase: 'Executing the restoration process will permanently erase your existing data.',
    restoreHint: `@:BackupRestorePage.restoreErase We strongly advise creating a backup file before
    proceeding with the restoration.`,
    restoreFile: 'Recovery file',
    restoreChooseFile: 'Choose a recovery file or drop it here...',
    restoreTitle: 'Are you sure?',
    restoreDescription: '@:BackupRestorePage.restoreErase <p>Please type <strong>{key}</strong> to confirm.</p>',
    success: 'Restoration completed',
  },

  DashboardPage: {
    title: 'Dashboard',
    productsTitle: 'Products',
    customersTitle: 'Customers',
    modulesTitle: 'Modules',
    licensesTitle: 'Licenses',
    chartRequestsDay: 'API Requests/Validations per Day',
    chartRevenueLicenseType: 'Revenue per License Type',
    chartLicensesRevenueDay: 'Licenses/Revenue per Day',
    chartLicenseeWarningLevel: 'Customers Warning Level',
  },

  CustomersPage: {
    title: 'Customers',
    deleteSuccessMessage: 'Customer deleted | {count} customers deleted',
  },

  CustomerCreatePage: {
    title: 'Create Customer',
    customerCreated: 'Customer created',
    cancel: 'Cancel',
  },

  CustomerEditPage: {
    title: '{number} | {name} [{number}]',
    customerUpdated: 'Customer updated',
    cancel: 'Cancel',
    deleteLicensesSuccessMessage: 'License deleted | {count} licenses deleted',
  },

  ProductsPage: {
    title: 'Products',
    deleteSuccessMessage: 'Product deleted | {count} products deleted',
  },

  ProductCreatePage: {
    title: 'Create Product',
    productCreated: 'Product created',
    cancel: 'Cancel',
  },

  ProductEditPage: {
    title: '{number} | {name} [{number}]',
    productUpdated: 'Product updated',
    deleteModulesSuccessMessage: 'Module deleted | {count} modules deleted',
    customers: '{count} customer | {count} customers',
  },

  ModuleCreatePage: {
    title: 'Create Module',
    moduleCreated: 'Module created',
    cancel: 'Cancel',
  },

  ModuleEditPage: {
    title: '{number} | {name} [{number}]',
    moduleUpdated: 'Module updated',
    cancel: 'Cancel',
  },

  LicenseCreatePage: {
    title: 'Create License',
    licenseCreated: 'License created',
    cancel: 'Cancel',
  },

  LicenseEditPage: {
    title: '{number} | {name} [{number}]',
    licenseUpdated: 'License updated',
    cancel: 'Cancel',
  },

  TransactionViewPage: {
    title: '{number}',
  },

  DemoDataPage: {
    title: 'Demo Data',
    description: `Here you can create a new product pre-configured with demo data for the chosen
            licensing model. The data corresponds to examples you will find in
            the Labs64 NetLicensing <a href="https://l64.cc/nlLM" target="_blank">Wiki.</a>`,
    create: 'Create',
    demoCreated: 'Demo data for {title} licensing model created',
    errorEntityDuplicated: 'Provided fixed part is already in use, please choose another.',
  },

  UsagePage: {
    title: 'Usage & Limits',
    plan: 'Your current plan is <span class=\'text-nlic\'>{plan}</span>',
    expires: 'Valid until {expires}',
    changePlan: 'Change Plan',
    attributionRequired: `As a free plan user, we encourage you to place NetLicensing attribution on your
            main page and/or within your product.
            <a href="https://www.labs64.com/legal/terms-of-service/netlicensing/#tos-attribution" target="_blank">
            More info
            </a>`,
  },

  DoTokenActionsPage: {
    tokenExpired: 'Token has expired',
    actionMismatch: 'Token action does not match the expected action',
    actionUnknown: 'Token action is unknown',
    login: 'Login',
    backToHome: 'Go back to the main page',
    needVerification: `To proceed with changing your email address, we need to verify your identity.
      Please enter your credentials.`,
    changeEmail: 'Change Email',
    emailChanged: 'Email has been changed',
  },

  SelfServicePortalPage: {
    title: 'Customer Portal',
    email: 'Email',
    phone: 'Phone',
    website: 'Website',
    city: 'City',
    address1: 'Address 1',
    address2: 'Address 2',
    zip: 'Zip/Postal Code',
    tos: 'Terms of Service',
    pp: 'Privacy Policy',
    imprint: 'Imprint',
  },

  TokenCreatePage: {
    title: 'Create API Key',
    apiKeyCreated: 'API Key {number} created',
    cancel: 'Cancel',
  },

  Error404Page: {
    title: 'Page Not Found',
    description: `We're sorry, the page you requested could not be found.
            Please go back to the dashboard or contact us at
            <a href="mailto:info@netlicensing.io">info@netlicensing.io</a>`,
    backToDashboard: 'Go back to the dashboard',
    backToHome: 'Go back to the main page',
  },

  Error500Page: {
    title: 'Oops! Something Went Wrong.',
    description: `The server encountered something unexpected and couldn't complete the request.
            We apologize for the inconvenience.`,
    sendError: `Please send this information as text (not screenshot) to
            <a href="mailto:info@netlicensing.io?body={error}">info@netlicensing.io</a>`,
    backToDashboard: 'Go back to the dashboard',
    backToHome: 'Go back to the main page',
  },

  Error403Page: {
    title: 'Access Forbidden',
    description: `We're sorry, but you do not have access to this page or resource.
            Please go back to the dashboard or contact us at
            <a href="mailto:info@netlicensing.io">info@netlicensing.io</a>`,
    backToDashboard: 'Go back to the dashboard',
    backToHome: 'Go back to the main page',
  },
};
