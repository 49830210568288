/* ============
 * Getters for the nlic module
 * ============
 *
 * The getters that are available on the
 * nlic module.
 */
import dayJs from 'dayjs';

// NetLicensing-Client
import { Context, Constants as ClientConstants } from 'netlicensing-client';

/**
 * Lodash functions
 * @see https://lodash.com/docs
 */
import _get from 'lodash/get';

export default {
  shopTokenExistsAndNotExpired(state) {
    return !!(_get(state.shopToken, 'active') && dayJs(state.shopToken.expirationTime).isAfter(dayJs()));
  },

  contextWithLicenseeRole() {
    const context = new Context();

    context.setBaseUrl(process.env.VUE_APP_CORE_BASE_URL);
    context.setSecurityMode(ClientConstants.APIKEY_IDENTIFICATION);
    context.setApiKey(process.env.VUE_APP_API_KEY_LICENSEE);

    return context;
  },

  dynamicEnvLoaded(state) {
    return !!state.dynamicEnv;
  },
};
