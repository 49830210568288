import { Service } from 'netlicensing-client';

export const authorize = (context, params) => Service
  .request(context, 'post', 'userinterface/authorizestripe', params);

export const deauthorize = (context, params = {}) => Service
  .request(context, 'post', 'userinterface/deauthorizestripe', params);

export default {
  authorize,
  deauthorize,
};
