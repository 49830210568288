/* ============
 * Getters for the statistic module
 * ============
 *
 * The getters that are available on the
 * statistic module.
 */

export default {
  productsTotal({ products }) {
    return products.active + products.inactive;
  },

  modulesTotal({ modules }) {
    return modules.active + modules.inactive;
  },

  templatesTotal({ templates }) {
    return templates.active + templates.inactive;
  },

  licensesTotal({ licenses }) {
    return licenses.active + licenses.inactive;
  },

  licenseesTotal({ licensees }) {
    return licensees.active + licensees.inactive;
  },

  notificationsTotal({ notifications }) {
    return notifications.active + notifications.inactive;
  },
};
