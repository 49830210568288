/* ============
 * Routes File
 * ============
 *
 * The routes and redirects are defined in this file.
 */
export default [
  // Login
  {
    path: '/login/:provider?',
    name: 'LoginPage',
    component: () => import('@/views/pages/LoginPage'),
    props: true,
    meta: { middleware: ['guest'] },
  },

  // Do
  {
    path: '/do/:token/:action',
    name: 'DoTokenActionsPage',
    component: () => import('@/views/pages/DoTokenActionsPage'),
    props: true,
  },

  // Registration
  {
    path: '/register/:provider?',
    name: 'RegistrationPage',
    component: () => import('@/views/pages/RegistrationPage'),
    props: true,
    meta: { middleware: ['guest'] },
  },

  // Forgot password
  {
    path: '/forgot-password',
    name: 'ForgotPasswordPage',
    component: () => import('@/views/pages/ForgotPasswordPage'),
    props: true,
  },

  // Dashboard
  {
    path: '/dashboard',
    name: 'DashboardPage',
    component: () => import('@/views/pages/DashboardPage'),
    meta: { middleware: ['auth'] },
  },

  // Products
  {
    path: '/products',
    name: 'ProductsPage',
    component: () => import('@/views/pages/ProductsPage'),
    meta: { middleware: ['auth'] },
  },

  // Products Create
  {
    path: '/products/create',
    name: 'ProductCreatePage',
    component: () => import('@/views/pages/ProductCreatePage'),
    meta: { middleware: ['auth'] },
  },

  // Products Edit
  {
    path: '/products/:number/edit',
    name: 'ProductEditPage',
    component: () => import('@/views/pages/ProductEditPage'),
    props: true,
    meta: { middleware: ['auth'] },
  },

  // Module Create
  {
    path: '/products/:productNumber/modules/create',
    name: 'ModuleCreatePage',
    component: () => import('@/views/pages/ModuleCreatePage'),
    props: true,
    meta: { middleware: ['auth'] },
  },

  // Module Edit
  {
    path: '/products/:productNumber/modules/:number/edit',
    name: 'ModuleEditPage',
    component: () => import('@/views/pages/ModuleEditPage'),
    props: true,
    meta: { middleware: ['auth'] },
  },

  // Customers
  {
    path: '/customers',
    name: 'CustomersPage',
    component: () => import('@/views/pages/CustomersPage'),
    meta: { middleware: ['auth'] },
  },

  // Customers Create
  {
    path: '/customers/create',
    name: 'CustomerCreatePage',
    component: () => import('@/views/pages/CustomerCreatePage'),
    meta: { middleware: ['auth'] },
  },

  // Customers Edit
  {
    path: '/customers/:number/edit',
    name: 'CustomerEditPage',
    component: () => import('@/views/pages/CustomerEditPage'),
    props: true,
    meta: { middleware: ['auth'] },
  },

  // Licenses Create
  {
    path: '/customers/:licenseeNumber/licenses/create',
    name: 'LicenseCreatePage',
    component: () => import('@/views/pages/LicenseCreatePage'),
    props: true,
    meta: { middleware: ['auth'] },
  },

  // Licenses Edit
  {
    path: '/customers/:licenseeNumber/licenses/:number/edit',
    name: 'LicenseEditPage',
    component: () => import('@/views/pages/LicenseEditPage'),
    props: true,
    meta: { middleware: ['auth'] },
  },

  // Transaction View
  {
    path: '/customers/:licenseeNumber/licenses/:licenseNumber/transactions/:number/view',
    name: 'TransactionViewPage',
    component: () => import('@/views/pages/TransactionViewPage'),
    props: true,
    meta: { middleware: ['auth'] },
  },

  // Profile
  {
    path: '/profile',
    name: 'ProfilePage',
    component: () => import('@/views/pages/ProfilePage'),
    meta: { middleware: ['auth'] },
  },

  // Settings
  {
    path: '/settings',
    name: 'SettingsPage',
    component: () => import('@/views/pages/SettingsPage'),
    meta: { middleware: ['auth'] },
  },

  // Tokens Create
  {
    path: '/tokens/create',
    name: 'TokenCreatePage',
    component: () => import('@/views/pages/TokenCreatePage'),
    meta: { middleware: ['auth'] },
  },

  // Notification Create
  {
    path: '/settings/notification/create',
    name: 'NotificationCreatePage',
    component: () => import('@/views/pages/NotificationCreatePage'),
    meta: { middleware: ['auth'] },
  },

  // Notification Update
  {
    path: '/settings/notification/:number/update',
    name: 'NotificationEditPage',
    component: () => import('@/views/pages/NotificationEditPage'),
    meta: { middleware: ['auth'] },
    props: true,
  },

  // Usage
  {
    path: '/usage',
    name: 'UsagePage',
    component: () => import('@/views/pages/UsagePage'),
    meta: { middleware: ['auth'] },
  },

  // Demo data
  {
    path: '/demo-data',
    name: 'DemoDataPage',
    component: () => import('@/views/pages/DemoDataPage'),
    meta: { middleware: ['auth'] },
  },

  // Bundles
  {
    path: '/bundles',
    name: 'BundlesPage',
    component: () => import('@/views/pages/BundlesPage'),
    meta: { middleware: ['auth'] },
  },

  // Bundle Create
  {
    path: '/bundles/create',
    name: 'BundleCreatePage',
    component: () => import('@/views/pages/BundleCreatePage'),
    meta: { middleware: ['auth'] },
  },

  // Customers Edit
  {
    path: '/bundles/:number/edit',
    name: 'BundleEditPage',
    component: () => import('@/views/pages/BundleEditPage'),
    props: true,
    meta: { middleware: ['auth'] },
  },

  // Backup & Restore
  {
    path: '/backup-restore',
    name: 'BackupRestorePage',
    component: () => import('@/views/pages/BackupRestorePage'),
    meta: { middleware: ['auth'] },
  },

  // test 500 page
  {
    path: '/test-500',
    name: 'TestError500Page',
    component: () => import('@/views/pages/TestError500Page'),
  },

  {
    path: '/',
    name: 'home',
    redirect: () => ({ name: 'DashboardPage' }),
  },

  // 404 page
  {
    path: '*',
    name: 'Error404Page',
    component: () => import('@/views/pages/Error404Page'),
  },

  // 403 page
  {
    path: '*',
    name: 'Error403Page',
    component: () => import('@/views/pages/Error403Page'),
  },

  // 500 page
  {
    path: '*',
    name: 'Error500Page',
    component: () => import('@/views/pages/Error500Page'),
    props: true,
  },
];
