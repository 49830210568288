/* ============
* Vuex Store
* ============
*
* Vuex is a state management pattern + library for Vue.js applications.
* It serves as a centralized store for all the components in an application,
* with rules ensuring that the state can only be mutated in a predictable fashion.
*
* https://vuex.vuejs.org/en/index.html
*/

import Vue from 'vue';
import Vuex from 'vuex';
import logger from 'vuex/dist/logger';

// long term storage
import LongTermStorage from '@/utils/LongTermStorage';

// Modules
import auth from './modules/auth';
import user from './modules/user';
import nlic from './modules/nlic';
import statistic from './modules/statistic';

const debug = process.env.NODE_ENV !== 'production';

Vue.use(Vuex);

const store = new Vuex.Store({
  /**
   * Assign the modules to the store.
   */
  modules: {
    auth,
    user,
    nlic,
    statistic,
  },

  /**
   * If strict mode should be enabled.
   */
  strict: debug,

  /**
   * Plugins used in the store.
   */
  plugins: debug ? [logger()] : [],
});

// save auth token to localStorage
store.subscribe((mutation, state) => {
  const { token = null } = state.auth;

  if (token) {
    LongTermStorage.set(process.env.VUE_APP_STORAGE_AUTH_TOKEN, token, true);
  } else {
    LongTermStorage.remove(process.env.VUE_APP_STORAGE_AUTH_TOKEN);
  }
});

export default store;
